import { forwardRef } from "react";
import { apply, tw } from "twind";

import { Slot } from "@radix-ui/react-slot";

const base = apply`text(blue-800 visited:purple-800) hover:underline focus:underline focus:outline-none ring(focus:2 focus:offset-2 focus:blue-500 visited:focus:purple-500)`;

export type LinkProps = React.ComponentProps<"a"> & { asChild?: boolean };
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { className, asChild, ...props },
  ref
) {
  const Comp = asChild ? Slot : "a";
  return <Comp ref={ref as any} className={tw(base, className)} {...props} />;
});
