import { forwardRef } from "react";
import { apply, tw } from "twind";

const base = apply`inline-flex items-center align-middle`;

const solid = {
  colors: {
    none: apply`text-gray-50 bg(gray-800 hover:gray-900)`,
    primary: apply`text-blue-50 bg(blue-800 hover:blue-900)`,
    success: apply`text-green-50 bg(green-800 hover:green-900)`,
    danger: apply`text-red-50 bg(red-800 hover:red-900)`,
    warning: apply`text-yellow-50 bg(yellow-800 hover:yellow-900)`,
    info: apply`text-purple-50 bg(purple-800 hover:purple-900)`,
    black: apply`text-white bg-black`,
  },
  close: {
    none: apply`text(gray-400 hover:gray-500 focus:white) bg(hover:gray-200 focus:gray-500) focus:outline-none`,
    primary: apply`text(blue-400 hover:blue-500 focus:white) bg(hover:blue-200 focus:blue-500) focus:outline-none`,
    success: apply`text(green-400 hover:green-500 focus:white) bg(hover:green-200 focus:green-500) focus:outline-none`,
    danger: apply`text(red-400 hover:red-500 focus:white) bg(hover:red-200 focus:red-500) focus:outline-none`,
    warning: apply`text(yellow-400 hover:yellow-500 focus:white) bg(hover:yellow-200 focus:yellow-500) focus:outline-none`,
    info: apply`text(purple-400 hover:purple-500 focus:white) bg(hover:purple-200 focus:purple-500) focus:outline-none`,
    black: apply`text(white focus:white) focus:outline-none`,
  },
} as const;

const light = {
  colors: {
    none: apply`text-gray-700 bg(gray-100 hover:gray-200 focus:gray-200)`,
    primary: apply`text-blue-700 bg(blue-100 hover:blue-200)`,
    secondary: apply`text-gray-700 bg(gray-100 hover:gray-200)`,
    success: apply`text-green-700 bg(green-100 hover:green-200)`,
    warning: apply`text-yellow-700 bg(yellow-100 hover:yellow-200)`,
    danger: apply`text-red-700 bg(red-100 hover:red-200)`,
    info: apply`text-purple-700 bg(purple-100 hover:purple-200) `,
    black: apply`text-white bg-gray-600`,
  },
  close: {
    none: apply`text(gray-700 hover:gray-900 focus:white) bg(hover:gray-200 focus:gray-500) focus:outline-none`,
    primary: apply`text(blue-700 hover:blue-900 focus:white) bg(hover:blue-200 focus:blue-500) focus:outline-none`,
    success: apply`text(green-700 hover:green-900 focus:white) bg(hover:green-200 focus:green-500) focus:outline-none`,
    danger: apply`text(red-700 hover:red-900 focus:white) bg(hover:red-200 focus:red-500) focus:outline-none`,
    warning: apply`text(yellow-700 hover:yellow-900 focus:white) bg(hover:yellow-200 focus:yellow-500) focus:outline-none`,
    info: apply`text(purple-700 hover:purple-900 focus:white) bg(hover:purple-200 focus:purple-500) focus:outline-none`,
    black: apply`text(white focus:white) focus:outline-none`,
  },
} as const;

const variants = {
  solid,
  light,
} as const;

const sizes = {
  sm: apply`px-2.5 py-0.5 text-xs font-medium`,
  lg: apply`px-3 py-0.5 text-sm font-medium`,
} as const;

const rounded = {
  sm: apply`rounded-sm`,
  lg: apply`rounded-md`,
  full: apply`rounded-full`,
} as const;

export type BadgeVariant = keyof typeof variants;
export type BadgeIntent = keyof typeof solid["colors"];

export type BadgeProps = React.ComponentProps<"span"> & {
  variant?: BadgeVariant;
  intent?: BadgeIntent;
  size?: keyof typeof sizes;
  rounded?: keyof typeof rounded;
  dot?: boolean;
  closeable?: boolean;
  onClose?: () => void;
};

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(function Badge(
  {
    variant = "light",
    intent = "none",
    size = "sm",
    rounded: r = "full",
    dot = false,
    closeable,
    onClose,
    className,
    children,
    ...props
  },
  ref
) {
  const selected = variants[variant];
  return (
    <span
      ref={ref}
      className={tw(base, selected.colors[intent], sizes[size], rounded[r], className)}
      {...props}
    >
      {dot && <Dot />}
      {children}
      {closeable && <Close variant={variant} intent={intent} onClose={onClose} />}
    </span>
  );
});

function Dot() {
  return (
    <svg className={tw`-ml-1 mr-1.5 h-2 w-2`} fill="currentColor" viewBox="0 0 8 8">
      <circle cx="4" cy="4" r="3" />
    </svg>
  );
}

const closeBase = apply`flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center`;

function Close({
  variant,
  intent,
  onClose,
}: {
  variant: BadgeVariant;
  intent: BadgeIntent;
  onClose?: () => void;
}) {
  const selected = variants[variant];
  return (
    <button type="button" className={tw(closeBase, selected.close[intent])} onClick={onClose}>
      <span className={tw`sr-only`}>Delete</span>
      <svg className={tw`h-2 w-2`} stroke="currentColor" fill="none" viewBox="0 0 8 8">
        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
    </button>
  );
}
