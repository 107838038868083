import React from "react";

const PortalContext = React.createContext<boolean>(false);

export function PortalContainerProvider({
  isInPortal,
  children,
}: {
  isInPortal: boolean;
  children: React.ReactNode;
}) {
  return <PortalContext.Provider value={isInPortal}>{children}</PortalContext.Provider>;
}

export function usePortalContainer() {
  return React.useContext(PortalContext);
}
