import React, { useMemo } from "react";
import { tw, apply } from "twind";
import { useIntent } from "../../context/IntentContext";
import { IntentType } from "./types";

const base = apply`form-select shadow-sm block w-full text-base sm:text-sm rounded-md focus:outline-none`;

const colors = {
  none: apply`focus:ring-gray-500 focus:border-gray-500 border-gray-300`,
  primary: apply`focus:ring-blue-500 focus:border-blue-500 border-blue-300`,
  secondary: apply`focus:ring-gray-500 focus:border-gray-500 border-gray-300`,
  success: apply`focus:ring-green-500 focus:border-green-500 border-green-300`,
  warning: apply`focus:ring-yellow-500 focus:border-yellow-500 border-yellow-300`,
  danger: apply`focus:ring-red-500 focus:border-red-500 border-red-300`,
  info: apply`focus:ring-purple-500 focus:border-purple-500 border-purple-300`,
} as const;

export type SelectProps = React.ComponentProps<"select"> & {
  // weight?: string | number | (string | number)[];
  intent?: keyof typeof colors;
};

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(function Select(
  { intent = "none", className, ...props },
  ref
) {
  const selectedIntent = useIntent(intent);
  return (
    <select className={tw(base, selectedIntent && colors[selectedIntent], className)} {...props} />
  );
});

// type TextSelectOptions = { value: string | number; text: string };

// function parseSimpleToComplexOption(
//   option: TextSelectOptions | string | number
// ): TextSelectOptions {
//   return typeof option !== "string" &&
//     typeof option !== "number" &&
//     "text" in option &&
//     "value" in option
//     ? option
//     : { value: option, text: String(option) };
// }

// export type TextSelectProps = SelectProps & { options: string[] };

// export const TextSelect: ForwardRef<HTMLSelectElement, TextSelectProps> = React.forwardRef(
//   function Select({ weight, intent, options, value, ...props }, ref) {
//     const parsedOptions = options.map(parseSimpleToComplexOption);

//     const [currentText] = parsedOptions.filter((o) => o.value === value);

//     return (
//       <Box {...getMargin(props)} __css={{ display: "inline-block", position: "relative" }}>
//         <Box
//           data-intent={intent}
//           __themeKey="forms"
//           __css={{
//             display: "inline-flex",
//             fontSize: weight ?? "inherit",
//             lineHeight: "inherit",
//             color: "inherit",
//             bg: "transparent",
//             pointerEvents: "none",
//             userSelect: "none",
//           }}
//         >
//           {currentText?.text ?? ""}
//           <DownArrow color="black" />
//         </Box>
//         <Box
//           ref={ref as any}
//           as="select"
//           data-component="TextSelect"
//           {...omitMargin(props)}
//           __css={{
//             opacity: 0,
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             position: "absolute",
//             width: "100%",
//             height: "100%",
//           }}
//         >
//           {parsedOptions.map((o) => (
//             <option key={o.value} value={o.value}>
//               {o.text}
//             </option>
//           ))}
//         </Box>
//         <Box
//           data-intent={intent}
//           __css={{
//             position: "absolute",
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             width: "100%",
//             height: "100%",
//             pointerEvents: "none",
//             "select:focus + &": {
//               outline: "1px solid",
//               outlineOffset: "-2px",
//               outlineColor: "primary",
//               '&[data-intent="success"]': {
//                 outlineColor: "success",
//               },
//               '&[data-intent="warning"]': {
//                 outlineColor: "warning",
//               },
//               '&[data-intent="danger"]': {
//                 outlineColor: "danger",
//               },
//             },
//           }}
//         />
//       </Box>
//     );
//   }
// );
