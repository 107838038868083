import * as DialogPrimitive from "@radix-ui/react-dialog";
import { tw, apply } from "twind";
import * as PortalPrimitive from "@radix-ui/react-portal";
import { useRef } from "react";
import { PortalContainerProvider } from "../context/PortalContext";

const baseOverlay = apply`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity
[data-state="closed"]:(ease-out duration-300 opacity-0)
[data-state="open"]:(ease-out duration-300 opacity-100)
`;

const baseContent = apply`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl
transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
[data-state="closed"]:(opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95)
[data-state="open"]:(opacity-100 translate-y-0 sm:scale-100)
`;

export type DialogProps = DialogPrimitive.DialogProps & {
  trigger: React.ReactNode;
  withOverlay?: boolean;
};
export function Dialog({ trigger, children, withOverlay }: DialogProps) {
  return (
    <DialogPrimitive.Root>
      <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
      <DialogPrimitive.Portal>
        <div
          className={tw(
            "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
          )}
        >
          {withOverlay && <DialogPrimitive.Overlay className={tw(baseOverlay)} />}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <DialogPrimitive.Content
            className={tw(
              "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            )}
          >
            <PortalContainerProvider isInPortal>{children}</PortalContainerProvider>
          </DialogPrimitive.Content>
        </div>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
}

Dialog.Title = DialogPrimitive.Title;
Dialog.Description = DialogPrimitive.Description;
Dialog.Close = DialogPrimitive.Close;

export function Modal({
  isOpen,
  onOpenChange,
  withOverlay = false,
  children,
}: {
  isOpen: boolean;
  onOpenChange?(isOpen: boolean): void;
  withOverlay?: boolean;
  children: React.ReactNode;
}) {
  return (
    <DialogPrimitive.Root open={isOpen} onOpenChange={onOpenChange}>
      <DialogPrimitive.Portal>
        <div
          className={tw(
            "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
          )}
        >
          {withOverlay && <DialogPrimitive.Overlay className={tw(baseOverlay)} />}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <DialogPrimitive.Content
            className={tw(
              "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            )}
          >
            <PortalContainerProvider isInPortal>{children}</PortalContainerProvider>
          </DialogPrimitive.Content>
        </div>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
}

Modal.Title = DialogPrimitive.Title;
Modal.Description = DialogPrimitive.Description;
Modal.Close = DialogPrimitive.Close;
