import React, { useMemo } from "react";
import { tw, apply, Directive, CSSRules } from "twind";
import { BREAKPOINTS } from "../utils/breakpoints";

type ColumnsGapProps = number | (number | null)[];

export type ColumnsProps = React.ComponentProps<"div"> & {
  isMultiline?: boolean;
  gap?: ColumnsGapProps;
};

export const Columns = React.forwardRef<HTMLDivElement, ColumnsProps>(function Columns(
  { gap, isMultiline, className, ...props },
  ref
) {
  const gapDirectives = useMemo(() => {
    if (!gap) return [];
    if (Array.isArray(gap)) {
      return gap.map((g, i) => {
        const b = BREAKPOINTS[i];
        return b == null ? apply(g) : apply(`${b}:${g}`);
      });
    }
    return [apply(`gap-${gap}`)];
  }, [gap]);

  return (
    <div
      ref={ref}
      className={tw("flex", isMultiline && "flex-wrap", ...gapDirectives, className)}
      {...props}
    />
  );
});

// we have the mapping of attributes we want to apply to the column
// for a specific breakpoint. We need to loop through that list by breakpoint
// to apply the right style to the column.
const columnWidthVariant = {
  narrow: ["flex-grow-0", "flex-shrink-0", "basis-auto"],
  "0": ["basis-0"],
  "1": ["basis-1"],
  "2": ["basis-2"],
  "3": ["basis-3"],
  "4": ["basis-4"],
  "5": ["basis-5"],
  "6": ["basis-6"],
  "7": ["basis-7"],
  "8": ["basis-8"],
  "9": ["basis-9"],
  "10": ["basis-10"],
  "11": ["basis-11"],
  "12": ["basis-12"],
  "14": ["basis-14"],
  "16": ["basis-16"],
  "20": ["basis-20"],
  "24": ["basis-24"],
  "28": ["basis-28"],
  "32": ["basis-32"],
  "36": ["basis-36"],
  "40": ["basis-40"],
  "44": ["basis-44"],
  "48": ["basis-48"],
  "52": ["basis-52"],
  "56": ["basis-56"],
  "60": ["basis-60"],
  "64": ["basis-64"],
  "72": ["basis-72"],
  "80": ["basis-80"],
  "96": ["basis-96"],
  auto: ["basis-auto"],
  px: ["basis-auto"],
  "0.5": ["basis-0.5"],
  "1.5": ["basis-1.5"],
  "2.5": ["basis-2.5"],
  "3.5": ["basis-3.5"],
  "1/2": ["basis-1/2"],
  "1/3": ["basis-1/3"],
  "2/3": ["basis-2/3"],
  "1/4": ["basis-1/4"],
  "2/4": ["basis-2/4"],
  "3/4": ["basis-3/4"],
  "1/5": ["basis-1/5"],
  "2/5": ["basis-2/5"],
  "3/5": ["basis-3/5"],
  "4/5": ["basis-4/5"],
  "1/6": ["basis-1/6"],
  "2/6": ["basis-2/6"],
  "3/6": ["basis-3/6"],
  "4/6": ["basis-4/6"],
  "5/6": ["basis-5/6"],
  "1/12": ["basis-1/12"],
  "2/12": ["basis-2/12"],
  "3/12": ["basis-3/12"],
  "4/12": ["basis-4/12"],
  "5/12": ["basis-5/12"],
  "6/12": ["basis-6/12"],
  "7/12": ["basis-7/12"],
  "8/12": ["basis-8/12"],
  "9/12": ["basis-9/12"],
  "10/12": ["basis-10/12"],
  "11/12": ["basis-11/12"],
  full: ["basis-full"],
};

type ColWithVariantsType = { width: Array<string> };

function generateWidthByBreakpoint(
  param: ColWithVariantsType | ColWithVariantsType[]
): Array<Directive<CSSRules>> {
  if (Array.isArray(param)) {
    return param
      .map((w, i) => {
        return w.width.map((d) => {
          const b = BREAKPOINTS[i];
          return apply(b == null ? d : `${b}:${d}`);
        });
      })
      .flat();
  }

  const directives = param.width;
  return [apply(...directives)];
}

type ColumnWithProps = keyof typeof columnWidthVariant;

export type ColumnProps = React.ComponentProps<"div"> & {
  width?: ColumnWithProps | ColumnWithProps[];
};

export const Column = React.forwardRef<HTMLDivElement, ColumnProps>(function Column(
  { width = "auto", className, ...props },
  ref
) {
  const directives = useMemo<Directive<CSSRules>[]>(() => {
    let mappedWith: ColWithVariantsType | ColWithVariantsType[];

    if (Array.isArray(width)) {
      mappedWith = width.map((w) => ({ width: columnWidthVariant[w] }));
    } else {
      mappedWith = { width: columnWidthVariant[width] };
    }

    return generateWidthByBreakpoint(mappedWith);
  }, [width]);

  return <div ref={ref} className={tw("flex", ...directives, className)} {...props} />;
});
