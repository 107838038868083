import { tw, apply } from "twind";

export type StepsProps = {};
export function Steps({ children }: React.PropsWithChildren<StepsProps>) {
  return (
    <nav aria-label="Progress">
      <ol role="list" className={tw`space-y-4 md:flex md:space-y-0 md:space-x-8`}>
        {children}
      </ol>
    </nav>
  );
}

const step = {
  base: apply`pl-4 py-2 flex flex-col border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4`,
  basePast: apply`group border-blue-600 hover:border-blue-800`,
  baseCurrent: apply`border-blue-600`,
  baseUpcoming: apply`border-gray-200 hover:border-gray-300`,

  title: apply`text-xs font-semibold tracking-wide uppercase`,
  titlePast: apply`text-blue-600 group-hover:text-indigo-800`,
  titleCurrent: apply`text-blue-600`,
  titleUpcoming: apply`text-gray-500 group-hover:text-gray-700`,

  description: apply`text-sm font-medium`,
} as const;

export type StepProps = {
  title: string;
  description: string;
  current?: "past" | "current" | "upcoming";
};
Steps.Step = function Step({ title, description, current }: StepProps) {
  return (
    <li className={tw`md:flex-1`}>
      <a
        href="#"
        className={tw(
          step.base,
          current === "past" && step.basePast,
          current === "current" && step.baseCurrent,
          current === "upcoming" && step.baseUpcoming
        )}
        aria-current={current === "current" ? "step" : undefined}
      >
        <span
          className={tw(
            step.title,
            current === "past" && step.titlePast,
            current === "current" && step.titleCurrent,
            current === "upcoming" && step.titleUpcoming
          )}
        >
          {title}
        </span>
        <span className={tw("text-sm font-medium")}>{description}</span>
      </a>
    </li>
  );
};
