import { useMemo } from "react";
import { apply, CSSRules, Directive } from "twind";

export const BREAKPOINTS = [null, "sm", "md", "lg", "xl", "2xl"] as const;

export function applyBreakpoint(
  elem: (string | number) | (string | number)[] | undefined,
  elements: Record<string, Directive<CSSRules>>
): Directive<CSSRules>[] {
  if (!elem) return [];
  if (Array.isArray(elem)) {
    return elem.map((s, i) => {
      const b = BREAKPOINTS[i];
      const attribute = elements[s];
      return b == null ? attribute : apply`${b}:${attribute}`;
    });
  }
  return [elements[elem]];
}

export function useApplyBreakpoints(
  elem: (string | number) | (string | number)[] | undefined,
  elements: Record<string, Directive<CSSRules>>
): Directive<CSSRules>[] {
  return useMemo(() => applyBreakpoint(elem, elements), [elem, elements]);
}
