import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

import { useInfiniteQuery, useQueryClient } from "react-query";
import * as ReachCB from "@reach/combobox";
import * as PortalPrimitive from "@radix-ui/react-portal";

import { apply, tw } from "twind";
import { css } from "twind/style";

import { Button, ButtonGroup } from "../Button";
import { Input, InputProps } from "./input";
import { useAutocompleteKiller } from "../../utils/formField.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { IntentProvider } from "../../context/IntentContext";
import { usePortalContainer } from "../../context/PortalContext";

const baseCombobox = css({
  ":global": {
    ":root": {
      "--reach-combobox": 1,
    },
    "[data-reach-combobox-popover]": {
      border: "solid 1px hsla(0, 0%, 0%, 0.25)",
      background: "hsla(0, 100%, 100%, 0.99)",
      fontSize: "85%",
    },

    "[data-reach-combobox-list]": {
      listStyle: "none",
      margin: 0,
      padding: 0,
      userSelect: "none",
    },

    "[data-reach-combobox-option]": {
      cursor: "pointer",
      margin: 0,
      padding: "0.25rem 0.5rem",
    },

    '[data-reach-combobox-option][aria-selected="true"]': {
      background: "hsl(211, 10%, 95%)",
    },

    "[data-reach-combobox-option]:hover": {
      background: "hsl(211, 10%, 92%)",
    },

    '[data-reach-combobox-option][aria-selected="true"]:hover': {
      background: "hsl(211, 10%, 90%)",
    },

    "[data-suggested-value]": {
      fontWeight: "bold",
    },
  },
});

const colors = {
  none: apply`text-black`,
  primary: apply`text-blue-900`,
  secondary: apply`text-black`,
  success: apply`text-green-900`,
  warning: apply`text-yellow-900`,
  danger: apply`text-red-900`,
  info: apply`text-purple-900`,
} as const;

const PAGE_SIZE = 50;

const { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover, ComboboxButton } =
  ReachCB;

export interface PagedResource<T> {
  data: T[];
  meta: {
    totalRecords: number;
    size: number;
  };
}

type AutocompleteAction =
  | { type: "SET_SEARCH"; payload: { term: string } }
  | { type: "SET_DISPLAY"; payload: { term: string; id: string | null } }
  | { type: "SYNC_ID"; payload: { id: string } }
  | { type: "CLEAR" };

const initialTerm = { selectedId: null, search: "", display: "" };
type AutocompleteStateType = { selectedId: string | null; search: string; display: string };
function reducer(state: AutocompleteStateType, action: AutocompleteAction): AutocompleteStateType {
  switch (action.type) {
    case "SET_SEARCH": {
      return {
        selectedId: state.selectedId,
        search: action.payload.term,
        display: action.payload.term,
      };
    }
    case "SET_DISPLAY": {
      return { selectedId: action.payload.id, search: state.search, display: action.payload.term };
    }
    case "SYNC_ID": {
      const asChanged = action.payload.id != state.selectedId;
      // SHOULD I DO SOMETHING ?
      return { selectedId: action.payload.id, search: state.search, display: state.display };
    }
    case "CLEAR":
      return initialTerm;
  }
}

export type AutocompleteProps = Omit<InputProps, "value" | "onChange" | "onChangeCapture"> & {
  value?: string | null;
  defaultValue?: string | null;
  queryKey: any[];
  loadMoreLabel: string;
  onValueChange?(value: string | null): void;
  fetchMore: (term: string, start: number, size: number) => Promise<PagedResource<any>>;
  fetchOne: (id: string) => Promise<Record<string, any>>;
};

export const Autocomplete = React.forwardRef<HTMLInputElement, AutocompleteProps>(
  function Autocomplete(
    { defaultValue, value, queryKey, loadMoreLabel, fetchMore, fetchOne, onValueChange, ...props },
    ref
  ) {
    /*
     * Les navigateurs propose tous seul de l'autocomplete sur les fields
     * Pour ignorer ça on devrait mettre [autocomplete = "off"]
     * Mais les navigateurs ignore cette dernière depuis peu
     * Alors on truande le système de la manière suivante
     * --------------------------------------------------
     * Lorsqu'un name est présent dans l'input,
     * il est préférable d'utiliser un uuid
     * Si on a pas de name a positionner
     * il vaut mieux mettre "off"
     */
    const autocompleteKiller = useAutocompleteKiller();

    const hiddenInputRef = useRef<HTMLInputElement | null>(null);
    const popOverRef = useRef<HTMLElement | null>(null);

    const optionClickedRef = useRef(false);
    const [term, dispatch] = useReducer(reducer, initialTerm);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const queryClient = useQueryClient();

    const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
      [...queryKey, term],
      ({ pageParam = 0 }) => fetchMore(term.search, pageParam, PAGE_SIZE),
      {
        staleTime: 60000,
        keepPreviousData: true,
        getNextPageParam: (lastPage, allPages) => {
          const first = allPages.length * PAGE_SIZE;
          return lastPage ? (lastPage.meta.totalRecords > first ? first : false) : false;
        },
        enabled: term.search !== null && term.search !== undefined && term.search !== "",
      }
    );

    const onSelect = useCallback(
      (label: string) => {
        const item = data?.pages
          .find(
            (page) =>
              page && page.data.find((el) => el.labelDetails === label || el.label === label)
          )
          ?.data.find((el) => el.labelDetails === label || el.label === label);

        setIsOpen(false);
        dispatch({
          type: "SET_DISPLAY",
          payload: { id: item?.id ?? null, term: item?.label ?? "" },
        });
      },
      [data, setIsOpen, dispatch]
    );

    const onClear = useCallback(() => {
      dispatch({ type: "CLEAR" });
      setIsOpen(false);
    }, [dispatch, setIsOpen]);

    const toggleSearch = useCallback(() => {
      setIsOpen(true);
      refetch();
    }, [refetch, setIsOpen]);

    const onTermChange = useCallback(
      (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length > 0) {
          setIsOpen(true);
        }
        let val = e.currentTarget.value;
        if (term.search === "") val = val.trim();
        dispatch({ type: "SET_SEARCH", payload: { term: val } });
      },
      [setIsOpen, dispatch, term.search]
    );

    const [isInitialized, setIsInitialized] = useState(false);
    const refIsControlled = useRef(defaultValue === undefined && value !== undefined);
    /*
     * Ne sert qu'a charger le label lié à l'id passé en value lors de l'initialisation de l'autocomplete
     */
    useEffect(() => {
      const isControlled = refIsControlled.current;
      const isStillControlled = defaultValue === undefined && value !== undefined;

      // on fait un warning sur le changement de defaultValue <=> value parce que cela peut causer des bugs.
      if (isControlled != isStillControlled) {
        console.warn(
          `WARNING: component <Autocomplete name="${props.name}" /> shouldn't change from controlled to uncontrolled and vice versa. Avoid interchanging defaultValue and value during runtime.`
        );
        refIsControlled.current = isStillControlled;
      }

      function updateInternal(val: string) {
        queryClient
          .fetchQuery([...queryKey, val], () => fetchOne(val))
          .then((data) => {
            dispatch({ type: "SET_DISPLAY", payload: { id: data?.id, term: data?.label ?? "" } });
          })
          .catch((error) =>
            console.log(
              `error during fetchOne after value (${val}) change for autocomplete ${props.name}`,
              error
            )
          );
      }

      if (isControlled) {
        if (value) {
          updateInternal(value);
        } else {
          // ici, on a besoin du ELSE parce que, si le composant est contrôlé, on *doit* synchroniser si le parent value change.
          dispatch({ type: "SET_DISPLAY", payload: { id: null, term: "" } });
        }
      } else {
        if (!isInitialized) {
          updateInternal(defaultValue);
        }
      }
    }, [isInitialized, defaultValue, value]);

    function resyncId(e: React.ChangeEvent<HTMLInputElement>) {
      const currentValue = e.currentTarget.value;
      const stateValue = term.selectedId;

      if (stateValue != currentValue) {
        dispatch({ type: "SYNC_ID", payload: { id: currentValue } });
      }
    }

    useEffect(() => {
      onValueChange?.(term.selectedId === "" ? null : term.selectedId);
    }, [term.selectedId]);

    const isInPortal = usePortalContainer();

    return (
      <Combobox onSelect={onSelect} openOnFocus={false}>
        <IntentProvider intent={props.intent}>
          <div className={tw("mt-1 relative rounded-md", baseCombobox)}>
            <ComboboxInput
              {...props}
              as={Input}
              className={tw(props.className, "pr-12")}
              ref={ref}
              name={autocompleteKiller}
              autoComplete={autocompleteKiller}
              selectOnClick
              value={term.display}
              onChange={onTermChange}
              disabled={props.disabled}
            />
            {/* 
              input type hidden est hyper pratique ici :
                - il nous permet d'avoir un *vrai* onChange
                - il nous permet de fonctionner avec des <form /> normaux
                - il nous permet, de resync, si jamais le formulaire est mis à jour autrement que par notre contrôle
                - pour le debug, on a toujours un <input type="hidden" /> associé à notre autocomplete
            */}
            <input
              type="hidden"
              ref={hiddenInputRef}
              name={props.name}
              value={term.selectedId ?? ""}
              onChange={resyncId}
            />
            <div className={tw("absolute inset-y-0 right-0 flex items-center")}>
              {value && value !== "" ? (
                <button
                  type="button"
                  className={tw(
                    "h-full py-0 px-3 inline-flex items-center justify-center",
                    props.intent && colors[props.intent]
                  )}
                  onClick={onClear}
                >
                  <FontAwesomeIcon icon={faTimes} transform="shrink-3" />
                </button>
              ) : (
                <ComboboxButton
                  as={"button"}
                  type="button"
                  className={tw(
                    "h-full py-0 px-3 inline-flex items-center justify-center",
                    props.intent && colors[props.intent]
                  )}
                  onClick={toggleSearch}
                >
                  <FontAwesomeIcon icon={faChevronDown} transform="shrink-3" />
                </ComboboxButton>
              )}
            </div>
          </div>
        </IntentProvider>

        {data && data.pages.length > 0 && data.pages[0].data.length > 0 && (
          <ComboboxPopover
            ref={popOverRef as any}
            // on désactive la portal si on est dans une portal
            portal={!isInPortal}
            className={tw("overflow-auto max-h-[300px] z-[100]", {
              hidden: isOpen === false,
            })}
            onMouseDown={() => {
              optionClickedRef.current = true;
            }}
          >
            <ComboboxList>
              {data.pages.map((page, i) => (
                <React.Fragment key={i}>
                  {page?.data.map(
                    (el) =>
                      el && (
                        <ComboboxOption
                          key={el.id}
                          value={el.label}
                          onMouseDown={() => {
                            optionClickedRef.current = true;
                          }}
                        />
                      )
                  )}
                </React.Fragment>
              ))}
            </ComboboxList>
            {hasNextPage && (
              <Button
                tabIndex={-1}
                type="button"
                variant="text"
                size="xs"
                onMouseDown={() => {
                  optionClickedRef.current = true;
                }}
                onClick={() => fetchNextPage()}
                className={tw("w-full justify-center hover:bg-gray-100")}
              >
                {loadMoreLabel}
              </Button>
            )}
          </ComboboxPopover>
        )}
      </Combobox>
    );
  }
);
