import { format } from "date-fns";
import type * as React from "react";
import { FormField, useFormField } from "./form";
import { Input } from "./input";
import { Select } from "./select";
import { Checkbox } from "./checkbox";

import { convertValue, useAutocompleteKiller } from "../../utils/formField.utils";
import { Autocomplete, PagedResource } from "./Autocomplete";

type InputType = {
  I: "input";
  INUM: "input";
  CA: "input";
  CAH: "input";
  CH: "input";
  SD: "select";
  GS: "input";
};

type InputTypeAssocation = {
  input: React.ComponentProps<"input">;
  select: React.ComponentProps<"select">;
};

interface FormElementBase<T extends keyof InputType> {
  column: string;
  label: string;
  typeCompo: T;
  mandatory?: boolean;
  wvi: boolean;
}

interface FormElementInput
  extends FormElementBase<"I" | "INUM" | "CA" | "CAH" | "CH">,
    React.ComponentProps<"input"> {}

interface FormSelectElement extends FormElementBase<"SD">, React.ComponentProps<"select"> {
  options: { value: string; label: string }[];
}

interface FormAutocompleteElement extends FormElementBase<"GS">, React.ComponentProps<"input"> {
  queryKey: any[];
  loadMoreLabel: string;
  fetchMore: (term: string, start: number, size: number) => Promise<PagedResource<any>>;
  fetchOne: (id: string) => Promise<Record<string, any>>;
}

export type FormElement = FormElementInput | FormSelectElement | FormAutocompleteElement;

export type validationType = "NONE" | "ON_BLUR" | "ON_CHANGE";

function getValidationType(wvi: boolean, typeCompo: string): validationType {
  if (!wvi) {
    return "NONE";
  }
  if (typeCompo === "GS" || typeCompo === "SD" || typeCompo === "CH") {
    return "ON_CHANGE";
  }
  return "ON_BLUR";
}

function FormFieldSelectedComponent(props: { element: FormElement }) {
  const { column, wvi, mandatory, typeCompo, label, ...restProps } = props.element;
  const { value, intent, isLoading, onValueChange, onBlur } = useFormField(
    column,
    getValidationType(wvi, props.element.typeCompo)
  );
  const autocompleteKiller = useAutocompleteKiller();

  function mergedOnBlur(e: any) {
    props.element.onBlur?.(e as any);
    if (!e.isDefaultPrevented()) {
      onBlur(column);
    }
  }

  function mergedOnChange(e: any) {
    props.element.onChange?.(e as any);
    if (!e.defaultPrevented) {
      onValueChange(column, convertValue(e));
    }
  }

  switch (props.element.typeCompo) {
    case "I":
      return (
        <Input
          {...(restProps as any)}
          type="text"
          title={column}
          onBlur={mergedOnBlur}
          onChange={mergedOnChange}
          value={value ? value : ""}
          borderColor={intent}
          autoComplete={autocompleteKiller}
          name={autocompleteKiller}
          disabled={isLoading}
        />
      );

    case "INUM":
      return (
        <Input
          {...(restProps as any)}
          type="number"
          title={column}
          onBlur={mergedOnBlur}
          value={value ? value : ""}
          onChange={mergedOnChange}
          borderColor={intent}
          disabled={isLoading}
        />
      );
    case "CA":
      return (
        <Input
          {...(restProps as any)}
          type="date"
          title={column}
          onBlur={mergedOnBlur}
          onChange={mergedOnChange}
          value={value ? format(value, "YYYY-MM-DD") : undefined}
          borderColor={intent}
          disabled={isLoading}
        />
      );

    case "CAH":
      return (
        <Input
          {...(restProps as any)}
          type="datetime-local"
          title={column}
          onBlur={mergedOnBlur}
          onChange={mergedOnChange}
          value={value ? format(value, "YYYY-MM-DDTHH:mm") : ""}
          borderColor={intent}
          disabled={isLoading}
        />
      );

    case "CH":
      return (
        <Checkbox
          {...(restProps as any)}
          value={value !== null && value !== undefined ? value : {}}
          onChange={mergedOnChange}
          checked={value}
          intent={intent}
          disabled={isLoading}
        />
      );

    case "SD":
      return (
        <Select
          {...(restProps as any)}
          value={value}
          onChange={mergedOnChange}
          intent={intent}
          disabled={isLoading}
        >
          {!mandatory && <option />}
          {props.element.options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Select>
      );

    case "GS":
      return (
        <Autocomplete
          {...(restProps as any)}
          value={value}
          onValueChange={(value) => onValueChange(column, value)}
          intent={intent}
          loadMoreLabel={props.element.loadMoreLabel}
          queryKey={props.element.queryKey}
          fetchMore={props.element.fetchMore}
          fetchOne={props.element.fetchOne}
          disabled={isLoading}
        />
      );

    default:
      return <div></div>;
  }
}

export interface FormFieldsProps {
  definition: FormElement[];
}

export function FormFields(props: FormFieldsProps) {
  return (
    <>
      {props.definition.map((element) => {
        return (
          <FormField
            key={element.column}
            label={element.label}
            required={element.mandatory}
            name={element.column}
          >
            <FormFieldSelectedComponent element={element} />
          </FormField>
        );
      })}
    </>
  );
}
