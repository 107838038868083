import React, { createContext, useContext } from "react";

type IntentType = "none" | "primary" | "secondary" | "success" | "warning" | "danger" | "info";

const IntentContext = createContext<IntentType | undefined>(undefined);

export function IntentProvider({
  intent,
  children,
}: React.PropsWithChildren<{ intent?: IntentType }>) {
  const parentIntent = useContext(IntentContext);

  return <IntentContext.Provider value={intent || parentIntent}>{children}</IntentContext.Provider>;
}

export function useIntent(controlledIntent?: IntentType | null) {
  const context = useContext(IntentContext);

  return controlledIntent || context;
}
