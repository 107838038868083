import React, { useContext, useMemo, FC } from "react";

import {
  createMachine,
  spawn,
  assign,
  send,
  sendParent,
  AnyEventObject,
  State,
  SpawnedActorRef,
  Interpreter,
  StateMachine,
} from "xstate";
import { nanoid } from "nanoid";

import { isBefore, addDays } from "date-fns";

import { useMachine, useActor, useSelector } from "@xstate/react";
import nProgress from "nprogress";

export interface ProcessusAdvancedFormInitData {
  id: string;
  entities: Record<string, any>[];
  // on a un double tableau parce qu'on marche par index de step
  paramsAdd: Record<string, any>[];
}

export type ProcessusDefinitionNature = "traitement" | "edition" | "navigation";
export enum ProcessusDefinitionNatureValue {
  TRAITEMENT = "traitement",
  EDITION = "edition",
  NAVIGATION = "navigation",
}

export interface ProcessusActorContext {
  processusConfig: {
    module: string;
    type: ProcessusDefinitionNature;
    compositeID: string;
    selected: Record<string, any>[];
    paramsAdd?: Record<string, any>[];
    label: string;
    executeAt?: string;
    editionType?: "rapide" | "apercu";
  } | null;
  jobID: string | null;
  error: string | null;
  result: any | null;
}

export type JobState = "started" | "error" | "execute" | "wait" | "check" | "fetchResult" | "done";

export enum JobStatesEnum {
  STARTED = "started",
  ERROR = "error",
  EXECUTE = "execute",
  WAIT = "wait",
  CHECK = "check",
  FETCH_RESULT = "fetchResult",
  DONE = "done",
}

export enum ProcessusJobStatus {
  DONE = "DONE",
  SCHEDULED = "DONE",
  READY = "READY",
  RUNNING = "RUNNING",
}

type ActionOutNavType = "INT" | "EXT";
interface ActionOut {
  type: ActionOutNavType;
  url: string;
}
export interface ProcessusResult {
  status: NotificationIntent;
  nav?: ActionOut;
  message?: string;
}

export type NotificationPriority = "TEMPORARY" | "LOW" | "NORMAL" | "HIGH" | "CRITICAL";
export type NotificationIntent = "DEFAULT" | "DANGER" | "WARNING" | "SUCCESS" | "INFO";
export enum NotificationGroup {
  DEFAULT = "DEFAULT",
  EDITION = "EDITION",
  METIER = "METIER",
  INFO = "INFO",
}
export interface NotificationActionDirectLink {
  type: "DIRECT_LINK";
  label: string;
  url: string;
}

const createProcessusMachine = (
  config: ProcessusActorContext["processusConfig"],
  executeProcessus: (
    module: string,
    compositeID: string,
    selected: Record<string, any>[],
    optional: { executeAt?: string; modeEdition?: string }
  ) => Promise<string>,
  executeProcessusAdvanced: (
    module: string,
    compositeID: string,
    formData: ProcessusAdvancedFormInitData,
    optional: { executeAt?: string; modeEdition?: string }
  ) => Promise<string>,
  checkProcessusExecution: (jobID: string) => Promise<ProcessusJobStatus>,
  fetchProcessusResult: (jobID: string) => Promise<ProcessusResult>
) =>
  createMachine<ProcessusActorContext>({
    initial: "started",
    context: {
      processusConfig: config,
      jobID: null,
      error: null,
      result: null,
    },
    on: {
      stop: "done",
    },
    states: {
      started: {
        always: [
          {
            target: "error",
            cond: (ctx) => ctx.processusConfig == null,
            actions: assign({ error: (_) => "missing configuration to launch the process" }),
          },
          { target: "execute" },
        ],
      },
      execute: {
        onEntry: (ctx) => {
          if (!ctx.processusConfig?.executeAt) {
            nProgress.start();
          }
        },
        invoke: {
          id: "register-process",
          src: async ({ processusConfig }) => {
            if (processusConfig == null)
              return Promise.reject("missing configuration to launch the process");

            try {
              if (processusConfig.paramsAdd) {
                const res = await executeProcessusAdvanced(
                  processusConfig.module,
                  processusConfig.compositeID,
                  {
                    id: processusConfig.compositeID,
                    entities: processusConfig.selected as Record<string, any>[],
                    paramsAdd: processusConfig.paramsAdd,
                  },
                  { executeAt: processusConfig.executeAt, modeEdition: processusConfig.editionType }
                );
                return res;
              } else {
                const res = await executeProcessus(
                  processusConfig.module,
                  processusConfig.compositeID,
                  processusConfig.selected,
                  { executeAt: processusConfig.executeAt, modeEdition: processusConfig.editionType }
                );
                return res;
              }
            } catch (err) {
              return await Promise.reject(err.response?.data);
            }
          },
          onDone: [
            {
              target: "wait",
              cond: (ctx) => ctx.processusConfig?.executeAt !== undefined,
              actions: assign({ jobID: (_, evt) => evt.data }),
            },
            { target: "check", actions: assign({ jobID: (_, evt) => evt.data }) },
          ],
          onError: {
            target: "error",
            actions: assign({
              error: (ctx, evt) => evt.data,
            }),
          },
        },
      },
      wait: {
        invoke: {
          id: "wait-timer",
          src: (ctx, event) => (callback, onReceive) => {
            const timeToExec = ctx.processusConfig?.executeAt as string; // as parce qu'on sait que ça ne peut pas être vide
            const startedAt = new Date();
            let scheduled = new Date();

            const [hours, minutes] = timeToExec.split(":");
            scheduled.setHours(parseInt(hours, 10));
            scheduled.setMinutes(parseInt(minutes, 10));

            if (isBefore(scheduled, startedAt)) {
              scheduled = addDays(scheduled, 1);
            }

            const id = setInterval(() => {
              const now = new Date();

              if (isBefore(scheduled, now)) {
                callback("startCheck");
              }
            }, 15 * 1000);

            return () => clearInterval(id);
          },
        },
        on: {
          startCheck: {
            target: "check",
            actions: () => {
              nProgress.start();
            },
          },
        },
      },
      check: {
        onEntry: () => {
          if (nProgress.isStarted()) nProgress.inc();
        },
        invoke: {
          id: "check-process",
          src: async ({ jobID }) => {
            if (jobID === null) return Promise.reject("cannot check process without jobID");
            try {
              const res = await checkProcessusExecution(jobID);
              return res;
            } catch (err) {
              return await Promise.reject(err.response?.data);
            }
          },
          onDone: [
            {
              cond: (_, evt) => {
                return evt.data === ProcessusJobStatus.DONE;
              },
              target: "fetchResult",
            },
            { target: "check" },
          ],
          onError: {
            target: "check",
          },
        },
      },
      fetchResult: {
        onEntry: () => {
          if (nProgress.isStarted()) nProgress.inc();
        },
        invoke: {
          id: "fetch-result",
          src: async ({ jobID }) => {
            if (jobID === null) return Promise.reject("cannot fetch-result process without jobID");

            try {
              const res = await fetchProcessusResult(jobID);
              return res;
            } catch (err) {
              return err.response?.data;
            }
          },
          onDone: {
            target: "done",
            actions: assign({ result: (_, evt) => evt.data }),
          },
        },
      },
      error: {
        type: "final",
        onEntry: [
          () => {
            if (nProgress.isStarted()) nProgress.done();
          },
          sendParent((ctx) => ({
            type: "PROCESS_END",
            id: ctx.processusConfig
              ? generateStableProcessId(
                  ctx.processusConfig.compositeID,
                  ctx.processusConfig.selected,
                  ctx.processusConfig.paramsAdd
                )
              : null,
            processusType: ctx.processusConfig?.type,
            error: ctx.error,
          })),
        ],
      },
      done: {
        type: "final",
        onEntry: [
          () => {
            if (nProgress.isStarted()) nProgress.done();
          },
          sendParent((ctx) => ({
            type: "PROCESS_END",
            id: ctx.processusConfig
              ? generateStableProcessId(
                  ctx.processusConfig.compositeID,
                  ctx.processusConfig.selected,
                  ctx.processusConfig.paramsAdd
                )
              : null,
            forceNotification:
              ctx.processusConfig?.executeAt != undefined && ctx.processusConfig?.executeAt != null,
            processusType: ctx.processusConfig?.type,
            data: ctx.result,
          })),
        ],
      },
    },
  });

interface ProcessusCheckActorContext {
  jobID: string | null;
  error: string | null;
  result: any | null;
}

export const createCheckProcessusMachine = (
  jobID: string,
  checkProcessusExecution: (jobID: string) => Promise<ProcessusJobStatus>,
  fetchProcessusResult: (jobID: string) => Promise<ProcessusResult>
) =>
  createMachine<ProcessusCheckActorContext>({
    initial: "started",
    context: {
      jobID: jobID,
      error: null,
      result: null,
    },
    states: {
      started: {
        always: [
          {
            target: "error",
            cond: (ctx) => ctx.jobID == null,
            actions: assign({ error: (_) => "missing configuration to launch the process" }),
          },
          { target: "check" },
        ],
      },
      check: {
        onEntry: () => {
          if (nProgress.isStarted()) {
            nProgress.inc();
          } else {
            nProgress.start();
          }
        },
        invoke: {
          id: "check-process",
          src: async ({ jobID }) => {
            if (jobID === null) return Promise.reject("cannot check process without jobID");
            try {
              const res = await checkProcessusExecution(jobID);
              return res;
            } catch (err) {
              return await Promise.reject(err.response?.data);
            }
          },
          onDone: [
            {
              cond: (_, evt) => {
                return evt.data === ProcessusJobStatus.DONE;
              },
              target: "fetchResult",
            },
            { target: "check" },
          ],
          onError: {
            target: "check",
          },
        },
      },
      fetchResult: {
        onEntry: () => {
          nProgress.inc();
        },
        invoke: {
          id: "fetch-result",
          src: async ({ jobID }) => {
            if (jobID === null) return Promise.reject("cannot fetch-result process without jobID");

            try {
              const res = await fetchProcessusResult(jobID);
              return res;
            } catch (err) {
              return err.response?.data;
            }
          },
          onDone: {
            target: "done",
            actions: assign({ result: (_, evt) => evt.data }),
          },
        },
      },
      error: {
        type: "final",
        onEntry: () => {
          nProgress.done();
        },
      },
      done: {
        type: "final",
        onEntry: () => {
          nProgress.done();
        },
      },
    },
  });

export type ProcessusManagerContextType = {
  info: Record<string, string | undefined>;
  processus: Record<
    string,
    SpawnedActorRef<StateMachine<ProcessusActorContext, any, AnyEventObject>>
  >;
  callback: Record<string, Function | undefined>;
};

export function generateStableProcessId(
  compositeID: string,
  selected: Record<string, any>[] | undefined,
  paramsAdd: Record<string, any>[] | undefined
) {
  if (selected) {
    const idsOrJson = selected.map((it) => (it.id ? it.id : JSON.stringify(it)));
    const stableSelected = new Set(idsOrJson);
    const stableSelectedString = [...(stableSelected as any)].join(",");
    return `job-${compositeID}-${stableSelectedString}`;
  } else if (paramsAdd) {
    const listOfStringyfiedParam = [];
    for (const oneParam of paramsAdd) {
      listOfStringyfiedParam.push(
        Object.keys(oneParam)
          .map((k) => `${k}_${oneParam[k]}`)
          .join(",")
      );
    }
    const stringyfiedParams = listOfStringyfiedParam.join("|");
    return `job-${compositeID}-${stringyfiedParams}`;
  } else {
    return compositeID;
  }
}

function managerFatory(
  executeProcessus: (
    module: string,
    compositeID: string,
    selected: Record<string, any>[],
    optional: { executeAt?: string; modeEdition?: string }
  ) => Promise<string>,
  executeProcessusAdvanced: (
    module: string,
    compositeID: string,
    formData: ProcessusAdvancedFormInitData,
    optional: { executeAt?: string; modeEdition?: string }
  ) => Promise<string>,
  checkProcessusExecution: (jobID: string) => Promise<ProcessusJobStatus>,
  fetchProcessusResult: (jobID: string) => Promise<ProcessusResult>,
  cancelProcessusExecution: (jobID: string) => Promise<ProcessusJobStatus>,
  notify: (params: {
    key?: string;
    title?: string;
    group: NotificationGroup.INFO;
    intent: NotificationIntent;
    priority: NotificationPriority;
    createdAt: string;
  }) => void,
  navigateTo: (url: string) => void
) {
  return createMachine<ProcessusManagerContextType>({
    id: "processus-manager",
    initial: "idle",
    context: {
      info: {},
      processus: {},
      callback: {},
    },
    states: {
      idle: {
        on: {
          cancel: {
            target: "cancelJob",
          },
          reset: {
            internal: true,
            actions: assign((ctx, evt) => {
              const stableID = generateStableProcessId(
                evt.compositeID,
                evt.selected,
                evt.paramsAdd
              );

              const autoID = ctx.info[stableID];

              if (!autoID) return ctx;

              const machine = ctx.processus[autoID];

              //side effect, on arrête la machine avant d'en faire la suppression
              machine.stop?.();

              return {
                info: {
                  ...ctx.info,
                  [stableID]: undefined,
                },
                processus: {
                  ...ctx.processus,
                  [autoID]: undefined,
                },
                callback: {
                  ...ctx.callback,
                  [autoID]: undefined,
                },
              } as ProcessusManagerContextType;
            }),
          },
          add: {
            internal: true,
            actions: assign((ctx, evt) => {
              const machine = createProcessusMachine(
                evt.config,
                executeProcessus,
                executeProcessusAdvanced,
                checkProcessusExecution,
                fetchProcessusResult
              );

              const autoID = nanoid();
              const stableID = generateStableProcessId(
                evt.config.compositeID,
                evt.config.selected,
                evt.config.paramsAdd
              );

              const existingMachineId = ctx.info[stableID];
              const existingMachine = existingMachineId && ctx.processus[existingMachineId];

              if (
                existingMachine &&
                !existingMachine.getSnapshot().matches("error") &&
                !existingMachine.getSnapshot().matches("done")
              ) {
                notify({
                  key: "commun_procesus_identique_en_cours",
                  group: NotificationGroup.INFO,
                  intent: "DANGER",
                  priority: "NORMAL",
                  createdAt: new Date().toISOString(),
                });
                return ctx;
              }

              const newContext = {
                info: {
                  ...ctx.info,
                  [stableID]: autoID,
                },
                processus: {
                  ...ctx.processus,
                  [autoID]: spawn(machine, { sync: true }),
                },
                callback: {
                  ...ctx.callback,
                  [autoID]: evt.callback,
                },
              };

              // on reset forcément l'ancien id si on arrive ici
              if (existingMachineId) newContext.processus[existingMachineId] = undefined as any;
              return newContext;
            }),
          },
          PROCESS_END: {
            internal: true,
            actions: [
              (ctx, evt) => {
                const processusResult = evt.data as ProcessusResult | null | undefined;

                if (!processusResult) return;

                if (
                  evt.forceNotification === false &&
                  processusResult.nav &&
                  evt.processusType === ProcessusDefinitionNatureValue.NAVIGATION
                ) {
                  if (processusResult.nav.type === "INT") {
                    navigateTo(processusResult.nav.url);
                  } else {
                    window.location.href = processusResult.nav.url;
                  }
                } else {
                  notifyAfterProcess(processusResult, notify);
                }
              },
              (ctx, evt) => {
                const autoId = ctx.info[evt.id];
                ctx.callback[autoId ?? ""]?.(ctx);
              },
              send("clearCallback"),
            ],
          },
        },
      },
      cancelJob: {
        invoke: {
          id: "cancel-job",
          src: (ctx, evt) => {
            const stableID = generateStableProcessId(evt.compositeID, evt.selected, evt.paramsAdd);
            const autoID = ctx.info[stableID];
            if (autoID === undefined)
              return Promise.reject("cannot cancel tamere without stableID");

            const machine = ctx.processus[autoID];
            const jobID = machine.getSnapshot().context.jobID;

            if (jobID === null) return Promise.reject("cannot cancel process without jobID");
            return cancelProcessusExecution(jobID).then((res) => ({
              compositeID: evt.compositeID,
              selected: evt.selected,
              status: res,
            }));
          },
          onDone: [
            {
              target: "idle",
              cond: (_, evt) => evt.data.status === ProcessusJobStatus.DONE,
              actions: assign((ctx, evt) => {
                const stableID = generateStableProcessId(
                  evt.data.compositeID,
                  evt.data.selected,
                  evt.data.paramsAdd
                );
                const autoID = ctx.info[stableID];
                if (!autoID) return ctx;

                const info = { ...ctx.info };
                delete info[stableID];

                const processus = { ...ctx.processus };
                delete processus[stableID];

                const callback = { ...ctx.callback };
                delete callback[stableID];
                const newContext = {
                  info,
                  processus,
                  callback,
                };

                const existingMachineId = ctx.info[stableID];
                // on reset forcément l'ancien id si on arrive ici
                if (existingMachineId) newContext.processus[existingMachineId] = undefined as any;
                return newContext;
              }),
            },
            {
              target: "idle",
              actions: () => {
                notify({
                  key: "commun_processus_pas_annule",
                  group: NotificationGroup.INFO,
                  intent: "DANGER",
                  priority: "NORMAL",
                  createdAt: new Date().toISOString(),
                });
              },
            },
          ],
          onError: {
            target: "idle",
          },
        },
      },
      clearCallback: {
        entry: [
          assign({
            callback: (ctx, evt) => {
              // une fois le callback executé, on a plus besoin de garder la fonction
              // la question reste encore en suspend pour la partie processus
              // ...
              // si on a besoin de notre result, il faut garder.
              // mais est-ce qu'on en a vraiment besoin ?
              // Sur le principe, on pourrait supprimer auto un processus après un certains temps depuis notre manager
              return {
                ...ctx.callback,
                [evt.id]: undefined,
              };
            },
          }),
          send("idle"),
        ],
      },
    },
  });
}

const ProcessusManagerContext = React.createContext<{
  state: State<ProcessusManagerContextType>;
  send: Interpreter<ProcessusManagerContextType>["send"];
} | null>(null);

interface ProcessusManagerProps {
  executeProcessus: (
    module: string,
    compositeID: string,
    selected: Record<string, any>[],
    optional: { executeAt?: string; modeEdition?: string }
  ) => Promise<string>;
  executeProcessusAdvanced: (
    module: string,
    compositeID: string,
    formData: ProcessusAdvancedFormInitData,
    optional: { executeAt?: string; modeEdition?: string }
  ) => Promise<string>;
  checkProcessusExecution: (jobID: string) => Promise<ProcessusJobStatus>;
  fetchProcessusResult: (jobID: string) => Promise<ProcessusResult>;
  cancelProcessusResult: (jobID: string) => Promise<ProcessusJobStatus>;
  notify: (params: {
    key?: string;
    title?: string;
    group: NotificationGroup.INFO;
    intent: NotificationIntent;
    priority: NotificationPriority;
    createdAt: string;
  }) => void;
  navigateTo: (url: string) => void;
}

export const ProcessusManager: FC<ProcessusManagerProps> = ({
  executeProcessus,
  executeProcessusAdvanced,
  checkProcessusExecution,
  fetchProcessusResult,
  cancelProcessusResult,
  notify,
  navigateTo,
  children,
}) => {
  const [state, send] = useMachine(
    managerFatory(
      executeProcessus,
      executeProcessusAdvanced,
      checkProcessusExecution,
      fetchProcessusResult,
      cancelProcessusResult,
      notify,
      navigateTo
    )
  );

  return (
    <ProcessusManagerContext.Provider value={{ state, send }}>
      {children}
    </ProcessusManagerContext.Provider>
  );
};

export function useInfoProcessus(stableID: string) {
  const context = useContext(ProcessusManagerContext);

  if (context == null) throw new Error("cannot use useInfoProcessus outside of <ProcessusManager>");

  const id = context.state.context.processus[stableID]
    ? stableID
    : context.state.context.info[stableID];

  const actorRef = useMemo(
    () => context.state.context.processus[id ?? ""],
    [context.state.context.processus, id]
  );

  const actor = useActor(actorRef);
  return actor;
}

function selectProcessusConfig(ctx: ProcessusActorContext) {
  return ctx.processusConfig;
}

export function useInfoProcessusSelector(id: string) {
  const context = useContext(ProcessusManagerContext);

  if (context == null)
    throw new Error("cannot use useInfoProcessusSelector outside of <ProcessusManager>");

  const actorRef = useMemo(
    () => context.state.context.processus[id],
    [context.state.context.processus, id]
  );

  return useSelector(actorRef, selectProcessusConfig);
}

export function useStableProcessusId(
  compositeID: string,
  selected: Record<string, any>[] | undefined,
  paramsAdd: Record<string, any>[] | undefined
) {
  return useMemo(
    () => generateStableProcessId(compositeID, selected, paramsAdd),
    [compositeID, paramsAdd, selected]
  );
}

export function useRegisterProcessus() {
  const context = useContext(ProcessusManagerContext);
  if (context == null) throw new Error("cannot use useInfoProcessus outside of <ProcessusManager>");

  function register(
    processusConfig: ProcessusActorContext["processusConfig"],
    callback?: (ctx: ProcessusManagerContextType) => void
  ) {
    if (!context) return;

    context.send({ type: "add", config: processusConfig, callback: callback } as any);
  }

  function reset(compositeID: string, selected: Record<string, any>[] | undefined) {
    if (!context) return;

    context.send({ type: "reset", compositeID: compositeID, selected } as any);
  }

  function cancel(compositeID: string, selected: Record<string, any>[] | undefined) {
    if (!context) return;

    context.send({ type: "cancel", compositeID, selected } as any);
  }

  return [context.state, { register, reset, cancel }] as const;
}

export function notifyAfterProcess(
  processusResult: ProcessusResult,
  notify: (params: {
    key?: string;
    title?: string;
    group: NotificationGroup.INFO;
    intent: NotificationIntent;
    priority: NotificationPriority;
    createdAt: string;
    actions?: NotificationActionDirectLink[];
  }) => void
) {
  // différentes possibilités
  // - un message et pas de nav
  // - un message et nav
  // - pas de message et nav
  // - pas de message et pas de nav

  if (processusResult.message) {
    let actionNav: NotificationActionDirectLink | undefined;
    if (processusResult.nav) {
      actionNav = {
        type: "DIRECT_LINK",
        label: "comet_naviguer",
        url: processusResult.nav.url,
      };
    }

    notify({
      title: processusResult.message,
      group: NotificationGroup.INFO,
      intent: processusResult.status,
      priority: "NORMAL",
      createdAt: new Date().toISOString(),
      actions: actionNav ? [actionNav] : undefined,
    });
  } else if (processusResult.nav) {
    let actionNav: NotificationActionDirectLink = {
      type: "DIRECT_LINK",
      label: "commun_naviguer",
      url: processusResult.nav.url,
    };

    notify({
      key: "comet_traitement_ok",
      group: NotificationGroup.INFO,
      intent: processusResult.status,
      priority: "NORMAL",
      createdAt: new Date().toISOString(),
      actions: [actionNav],
    });
  } else {
    notify({
      key: "comet_traitement_ok",
      group: NotificationGroup.INFO,
      intent: processusResult.status,
      priority: "NORMAL",
      createdAt: new Date().toISOString(),
    });
  }
}
