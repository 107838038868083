import * as PopoverPrimitive from "@radix-ui/react-popover";
import { tw } from "twind";

export type PopoverProps = PopoverPrimitive.PopoverProps & {
  trigger: PopoverPrimitive.PopoverTriggerProps;
};

export function Popover({ trigger, children, ...props }: PopoverProps) {
  return (
    <PopoverPrimitive.Root {...props}>
      <PopoverPrimitive.Trigger asChild>{trigger}</PopoverPrimitive.Trigger>
      <PopoverPrimitive.Content
        className={tw("bg-white border-1 border-gray-100 rounded-lg shadow pt-5 pb-4 px-4")}
        sideOffset={5}
      >
        {children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Root>
  );
}

Popover.Close = PopoverPrimitive.Close;
