import { forwardRef } from "react";
import { apply, tw } from "twind";

import * as MenuPrimitive from "@radix-ui/react-dropdown-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight, faCircle } from "@fortawesome/pro-solid-svg-icons";

// const slideDown = keyframes`
//   0% {
//     opacity: 0;
//     transform: translateY(-10px);
//   }
//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

export type MenuProps = { children: React.ReactNode } & MenuPrimitive.DropdownMenuProps;
export function Menu(props: MenuProps) {
  return <MenuPrimitive.Root {...props} />;
}

export type MenuTriggerProps = MenuPrimitive.DropdownMenuTriggerProps & {};
Menu.Trigger = forwardRef<HTMLButtonElement, MenuTriggerProps>(function MenuTrigger(
  { className, children, ...props },
  ref
) {
  return (
    <MenuPrimitive.Trigger ref={ref} asChild {...props}>
      {children}
    </MenuPrimitive.Trigger>
  );
});

export type MenuContentProps = MenuPrimitive.DropdownMenuContentProps & {};
Menu.Content = forwardRef<HTMLDivElement, MenuContentProps>(function MenuContent(
  { className, ...props },
  ref
) {
  return (
    <MenuPrimitive.Content
      ref={ref}
      className={tw(
        "mt-2 min-w-[10rem] max-w-[28rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
        className
      )}
      {...props}
    />
  );
});

export type MenuLabelProps = MenuPrimitive.DropdownMenuLabelProps & {};
Menu.Label = forwardRef<HTMLDivElement, MenuLabelProps>(function MenuLabel(
  { className, ...props },
  ref
) {
  return (
    <MenuPrimitive.Label
      ref={ref}
      className={tw("text-xs font-bold px-2 py-1 w-full", className)}
      {...props}
    />
  );
});

const baseItem = apply`relative flex items-center 
  text(gray-800 [data-disabled]:gray-500 hover:blue-800 focus:blue-800 [data-state="open"]:blue-800 [data-state="checked"]:blue-800) text-sm
  bg([data-state="open"]:blue-50 hover:blue-50 [data-disabled]:hover:gray-50 focus:blue-50)
  focus:outline-none`;

export type MenuItemProps = MenuPrimitive.DropdownMenuItemProps & {};
Menu.Item = forwardRef<HTMLDivElement, MenuItemProps>(function MenuItem(
  { className, children, ...props },
  ref
) {
  return (
    <MenuPrimitive.Item ref={ref} className={tw(baseItem, className)} {...props}>
      <div className={tw("px-4 py-2 w-full")}>{children}</div>
    </MenuPrimitive.Item>
  );
});

export type MenuGroupProps = MenuPrimitive.DropdownMenuGroupProps & {};
Menu.Group = forwardRef<HTMLDivElement, MenuGroupProps>(function MenuGroup(
  { className, ...props },
  ref
) {
  return <MenuPrimitive.Group ref={ref} className={tw("py-1", className)} {...props} />;
});

export type MenuCheckboxProps = MenuPrimitive.DropdownMenuCheckboxItemProps & {};
Menu.Checkbox = forwardRef<HTMLDivElement, MenuCheckboxProps>(function MenuCheckbox(
  { className, children, ...props },
  ref
) {
  return (
    <MenuPrimitive.CheckboxItem ref={ref} className={tw(baseItem, className)} {...props}>
      <Menu.ItemIndicator>
        <FontAwesomeIcon icon={faCheck} className={tw("text-blue-800")} transform="shrink-8" />
      </Menu.ItemIndicator>
      <div className={tw("px-4 py-2 w-full")}>{children}</div>
    </MenuPrimitive.CheckboxItem>
  );
});

export type MenuItemIndicatorProps = MenuPrimitive.DropdownMenuItemIndicatorProps & {};
Menu.ItemIndicator = forwardRef<HTMLSpanElement, MenuItemIndicatorProps>(function MenuItemIndicator(
  { className, ...props },
  ref
) {
  return (
    <MenuPrimitive.ItemIndicator
      ref={ref}
      className={tw("absolute left-1 w-2 flex justify-center items-center")}
      {...props}
    />
  );
});

export type MenuRadioGroupProps = MenuPrimitive.DropdownMenuRadioGroupProps & {};
Menu.RadioGroup = forwardRef<HTMLDivElement, MenuRadioGroupProps>(function MenuRadioGroup(
  { className, ...props },
  ref
) {
  return <MenuPrimitive.RadioGroup ref={ref} className={tw("py-1", className)} {...props} />;
});

export type MenuRadioItemProps = MenuPrimitive.DropdownMenuRadioItemProps & {};
Menu.RadioItem = forwardRef<HTMLDivElement, MenuRadioItemProps>(function MenuRadioItem(
  { className, children, ...props },
  ref
) {
  return (
    <MenuPrimitive.RadioItem ref={ref} className={tw(baseItem, className)} {...props}>
      <Menu.ItemIndicator>
        <FontAwesomeIcon icon={faCircle} className={tw("text-blue-800")} transform="shrink-10" />
      </Menu.ItemIndicator>
      <div className={tw("px-4 py-2 w-full")}>{children}</div>
    </MenuPrimitive.RadioItem>
  );
});

export type MenuTriggerItemProps = MenuPrimitive.DropdownMenuTriggerItemProps & {};
Menu.TriggerItem = forwardRef<HTMLDivElement, MenuTriggerItemProps>(function MenuTriggerItem(
  { className, children, ...props },
  ref
) {
  return (
    <MenuPrimitive.TriggerItem ref={ref} className={tw(baseItem, className)} {...props}>
      <div className={tw("px-4 py-2 w-full flex")}>
        {children}
        <div className={tw`ml-auto px-1 text-current [data-disabled]:text-gray-800`}>
          <FontAwesomeIcon icon={faChevronRight} width={8} height={8} transform="shrink-4" />
        </div>
      </div>
    </MenuPrimitive.TriggerItem>
  );
});

export type MenuSeparatorProps = MenuPrimitive.DropdownMenuSeparatorProps & {};
Menu.Separator = forwardRef<HTMLDivElement, MenuSeparatorProps>(function MenuSeparator(props, ref) {
  return (
    <MenuPrimitive.Separator
      ref={ref}
      className={tw("h-px w-11/12 bg-gray-300 mx-auto my-0.5")}
      {...props}
    />
  );
});

export type MenuArrowProps = MenuPrimitive.DropdownMenuArrowProps & {};
Menu.Arrow = forwardRef<SVGSVGElement, MenuArrowProps>(function MenuArrow(props, ref) {
  return <MenuPrimitive.Arrow ref={ref} {...props} />;
});
