import React, { useEffect, useMemo, useRef } from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { apply, tw } from "twind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHorizontalRule } from "@fortawesome/pro-solid-svg-icons";
import { useIntent } from "../../context/IntentContext";
import { css } from "twind/css";

const base = apply`form-checkbox shadow-sm block focus:outline-none`;

const colors = {
  none: apply`focus:ring-gray-500 focus:border-gray-500 border-gray-300 bg-gray-50)`,
  primary: apply`focus:ring-blue-500 focus:border-blue-500 border-blue-300 bg-blue-50)`,
  secondary: apply`focus:ring-gray-500 focus:border-gray-500 border-gray-300 bg-gray-50)`,
  success: apply`focus:ring-green-500 focus:border-green-500 border-green-300 bg-green-50)`,
  warning: apply`focus:ring-yellow-500 focus:border-yellow-500 border-yellow-300 bg-yellow-50)`,
  danger: apply`focus:ring-red-500 focus:border-red-500 border-red-300 bg-red-50)`,
  info: apply`focus:ring-purple-500 focus:border-purple-500 border-purple-300 bg-purple-50)`,
} as const;

const colorsIndicator = {
  none: apply`text-gray-800`,
  primary: apply`text-blue-800`,
  secondary: apply`text-gray-800`,
  success: apply`text-green-800`,
  warning: apply`text-yellow-800`,
  danger: apply`text-red-800`,
  info: apply`text-purple-800`,
} as const;

const sizes = {
  sm: apply`h-4 w-4 rounded-sm`,
  md: apply`h-6 w-6 rounded-sm`,
  lg: apply`h-8 w-8 rounded-sm`,
  xl: apply`h-10 w-10 rounded-sm`,
};

const sizesIcon = {
  sm: "shrink-7 up-5 left-1",
  md: "shrink-4 up-1 left-0",
  lg: "shrink-2 up-1 left-0",
  xl: "shrink-0 up-0 left-0",
};

const indeterminateSizesIcon = {
  sm: "shrink-9 up-5 left-3",
  md: "shrink-4 up-1 left-0",
  lg: "shrink-2 up-1 left-0",
  xl: "shrink-0 up-0 left-0",
};

export type CheckboxProps = CheckboxPrimitive.CheckboxProps & {
  intent?: keyof typeof colors;
  inputSize?: keyof typeof sizes;
};

const iconVisibilty = css`
  & > [data-icon="indeterminate"],
  & > [data-icon="checked"] {
    display: none;
  }
  &[data-state="indeterminate"] > [data-icon="indeterminate"] {
    display: inline-block;
  }
  &[data-state="checked"] > [data-icon="checked"] {
    display: inline-block;
  }
`;

export const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(function Checkbox(
  { inputSize = "sm", intent = "none", className, children, ...props },
  ref
) {
  const selectedIntent = useIntent(intent);
  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={tw(base, selectedIntent && colors[selectedIntent], sizes[inputSize], className)}
      {...props}
    >
      <CheckboxPrimitive.Indicator className={tw(iconVisibilty, colorsIndicator[intent])}>
        <FontAwesomeIcon
          data-icon="indeterminate"
          icon={faHorizontalRule}
          transform={indeterminateSizesIcon[inputSize]}
        />
        <FontAwesomeIcon data-icon="checked" icon={faCheck} transform={sizesIcon[inputSize]} />
      </CheckboxPrimitive.Indicator>
      {children}
    </CheckboxPrimitive.Root>
  );
});
