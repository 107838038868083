import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faSparkles,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, ComponentProps } from "react";
import { tw, apply } from "twind";
import { useIntent } from "../context/IntentContext";

const colors = {
  none: apply`text-gray-500`,
  primary: apply`text-blue-600`,
  secondary: apply`text-gray-600`,
  success: apply`text-green-600`,
  warning: apply`text-yellow-600`,
  danger: apply`text-red-600`,
  info: apply`text-purple-600`,
} as const;

const intentIcons = {
  none: faInfoCircle,
  primary: faInfoCircle,
  secondary: faInfoCircle,
  success: faCheckCircle,
  warning: faExclamationCircle,
  danger: faTimesCircle,
  info: faSparkles,
} as const;

export type NoticeProps = ComponentProps<"p"> & { intent?: keyof typeof colors };
export const Notice = forwardRef<HTMLParagraphElement, NoticeProps>(function Notice(
  { intent, className, children, ...props },
  ref
) {
  const selectedIntent = useIntent(intent);
  return (
    <p className={tw("text-sm", selectedIntent && colors[selectedIntent], className)} {...props}>
      {selectedIntent && intentIcons[selectedIntent] && (
        <span className={tw("mx-1")}>
          <FontAwesomeIcon icon={intentIcons[selectedIntent]} transform="shrink-1 up-1" />
        </span>
      )}
      {children}
    </p>
  );
});
