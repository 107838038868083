import * as React from "react";
import { apply, tw } from "twind";
import { style } from "twind/style";
import { useIntent } from "../context/IntentContext";

import { Slot } from "@radix-ui/react-slot";

const sizes = {
  none: apply`px-0.5 py-0.5 text-xs rounded`,
  xs: apply`px-2.5 py-1.5 text-xs rounded`,
  sm: apply`px-3 py-2 text-sm leading-4 rounded-md`,
  md: apply`px-4 py-2 text-sm rounded-md`,
  lg: apply`px-4 py-2 text-base rounded-md`,
  xl: apply`px-6 py-3 text-base rounded-md`,
} as const;

const solid = {
  base: apply`inline-flex items-center font-medium shadow-sm`,
  colors: {
    none: apply`border(& gray-300) text-gray-700 bg(white hover:gray-50) ring(focus:2 focus:offset-2 focus:gray-500) focus:outline-none`,
    primary: apply`border(& transparent) text-white bg(blue-600 hover:blue-700) ring(focus:2 focus:offset-2 focus:blue-500) focus:outline-none`,
    secondary: apply`border(& transparent) text-white bg(gray-600 hover:gray-700) ring(focus:2 focus:offset-2 focus:gray-500) focus:outline-none`,
    success: apply`border(& transparent) text-white bg(green-600 hover:green-700) ring(focus:2 focus:offset-2 focus:green-500) focus:outline-none`,
    warning: apply`border(& transparent) text-white bg(yellow-600 hover:yellow-700) ring(focus:2 focus:offset-2 focus:yellow-500) focus:outline-none`,
    danger: apply`border(& transparent) text-white bg(red-600 hover:red-700) ring(focus:2 focus:offset-2 focus:red-500) focus:outline-none`,
    info: apply`border(& transparent) text-white bg(purple-600 hover:purple-700) ring(focus:2 focus:offset-2 focus:purple-500) focus:outline-none`,
  },
  sizes,
} as const;

const outlined = {
  base: apply`inline-flex items-center font-medium shadow-sm`,
  colors: {
    none: apply`border(& gray-300) text-gray-700 bg(white hover:gray-50 focus:gray-50) ring(focus:2 focus:offset-2 focus:gray-500) focus:outline-none`,
    primary: apply`border(& blue-300) text-blue-700 bg(white hover:blue-50 focus:blue-50) ring(focus:2 focus:offset-2 focus:blue-500) focus:outline-none`,
    secondary: apply`border(& gray-300) text-gray-700 bg(white hover:gray-50 focus:gray-50) ring(focus:2 focus:offset-2 focus:gray-500) focus:outline-none`,
    success: apply`border(& green-300) text-green-700 bg(white hover:green-50 focus:green-50) ring(focus:2 focus:offset-2 focus:green-500) focus:outline-none`,
    warning: apply`border(& yellow-300) text-yellow-700 bg(white hover:yellow-50 focus:yellow-50) ring(focus:2 focus:offset-2 focus:yellow-500) focus:outline-none`,
    danger: apply`border(& red-300) text-red-700 bg(white hover:red-50 focus:red-50) ring(focus:2 focus:offset-2 focus:red-500) focus:outline-none`,
    info: apply`border(& purple-300) text-purple-700 bg(white hover:purple-50 focus:purple-50) ring(focus:2 focus:offset-2 focus:purple-500) focus:outline-none`,
  },
  sizes,
} as const;

const light = {
  base: apply`inline-flex items-center font-medium shadow-sm`,
  colors: {
    none: apply`border(& gray-300) text-gray-700 bg(white hover:gray-50 focus:gray-50) ring(focus:2 focus:offset-2 focus:gray-500) focus:outline-none`,
    primary: apply`border(& transparent) text-blue-700 bg(blue-100 hover:blue-200) focus:outline-none ring(focus:2 focus:offset-2 focus:blue-500)`,
    secondary: apply`border(& transparent) text-gray-700 bg(gray-100 hover:gray-200) focus:outline-none ring(focus:2 focus:offset-2 focus:gray-500)`,
    success: apply`border(& transparent) text-green-700 bg(green-100 hover:green-200) focus:outline-none ring(focus:2 focus:offset-2 focus:green-500)`,
    warning: apply`border(& transparent) text-yellow-700 bg(yellow-100 hover:yellow-200) focus:outline-none ring(focus:2 focus:offset-2 focus:yellow-500)`,
    danger: apply`border(& transparent) text-red-700 bg(red-100 hover:red-200) focus:outline-none ring(focus:2 focus:offset-2 focus:red-500)`,
    info: apply`border(& transparent) text-purple-700 bg(purple-100 hover:purple-200) focus:outline-none ring(focus:2 focus:offset-2 focus:purple-500)`,
  },
  sizes,
} as const;

const circle = {
  base: apply`inline-flex items-center p-1 rounded-full shadow-sm`,
  colors: {
    none: apply`border(& gray-300) text-black bg-white hover:bg-gray-50 focus:outline-none ring(focus:2 focus:offset-2 focus:gray-500)`,
    primary: apply`border(& transparent) text-white bg-blue-600 hover:bg-blue-700 focus:outline-none ring(focus:2 focus:offset-2 focus:blue-500)`,
    secondary: apply`border(& transparent) text-white bg-gray-600 hover:bg-gray-700 focus:outline-none ring(focus:2 focus:offset-2 focus:gray-500)`,
    success: apply`border(& transparent) text-white bg-green-600 hover:bg-green-700 focus:outline-none ring(focus:2 focus:offset-2 focus:green-500)`,
    warning: apply`border(& transparent) text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none ring(focus:2 focus:offset-2 focus:yellow-500)`,
    danger: apply`border(& transparent) text-white bg-red-600 hover:bg-red-700 focus:outline-none ring(focus:2 focus:offset-2 focus:red-500)`,
    info: apply`border(& transparent) text-white bg-purple-600 hover:bg-purple-700 focus:outline-none ring(focus:2 focus:offset-2 focus:purple-500)`,
  },
  sizes: {
    none: null,
    xs: apply`p-1`,
    sm: apply`p-1.5`,
    md: apply`p-2`,
    lg: apply`p-2`,
    xl: apply`p-3`,
  },
} as const;

const text = {
  base: apply`inline-flex items-center font-medium`,
  colors: {
    none: apply`text(gray-700 gray-800) ring(focus:2 focus:offset-2 focus:gray-500) focus:outline-none`,
    primary: apply`text(blue-700 hover:blue-800) focus:outline-none ring(focus:2 focus:offset-2 focus:blue-500)`,
    secondary: apply`text(gray-700 hover:gray-800) focus:outline-none ring(focus:2 focus:offset-2 focus:gray-500)`,
    success: apply`text(green-700 hover:green-800) focus:outline-none ring(focus:2 focus:offset-2 focus:green-500)`,
    warning: apply`text(yellow-700 hover:yellow-800) focus:outline-none ring(focus:2 focus:offset-2 focus:yellow-500)`,
    danger: apply`text(red-700 hover:red-800) focus:outline-none ring(focus:2 focus:offset-2 focus:red-500)`,
    info: apply`text(purple-700 hover:purple-800) focus:outline-none ring(focus:2 focus:offset-2 focus:purple-500)`,
  },
  sizes,
} as const;

const variants = {
  solid,
  outlined,
  light,
  circle,
  text,
};

export type IntentButtonType = keyof typeof solid.colors;
export type SizeButtonType = keyof typeof solid.sizes;

export type ButtonProps = React.ComponentProps<"button"> & {
  variant?: keyof typeof variants;
  intent?: IntentButtonType | null;
  size?: SizeButtonType;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { intent, size = "md", variant = "solid", className, ...props },
  ref
) {
  const selected = variants[variant];
  const intentValue = useIntent(intent) || "none";

  return (
    <button
      ref={ref}
      className={tw(selected.base, selected.colors[intentValue], selected.sizes[size], className)}
      {...props}
    />
  );
});

export type ButtonLinkProps = React.ComponentProps<"a"> & {
  variant?: keyof typeof variants;
  intent?: IntentButtonType;
  size?: SizeButtonType;
  asChild?: boolean;
};
export const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(function ButtonLink(
  { variant = "text", intent = "none", size = "md", asChild = true, className, ...props },
  ref
) {
  const selected = variants[variant];
  const intentValue = useIntent(intent) || "none";

  const Comp = asChild ? Slot : "a";

  return (
    <Comp
      ref={ref as any}
      className={tw(selected.base, selected.colors[intentValue], selected.sizes[size], className)}
      {...props}
    />
  );
});

const groupButtonBase = apply`
not-first-child:-ml-px relative rounded-none first-child:rounded-l-md last-child:rounded-r-md focus:z-10
`;

export type ButtonGroupProps = React.ComponentProps<"div"> & {};
export const ButtonGroup = React.forwardRef<HTMLDivElement, ButtonGroupProps>(function ButtonGroup(
  { className, children, ...props },
  ref
) {
  return (
    <div
      ref={ref}
      className={tw("relative z-0 inline-flex shadow-sm rounded-md", className)}
      {...props}
    >
      {React.Children.map(children, (child, index) => {
        if (!child) return null;
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { className: groupButtonBase });
        }
      })}
    </div>
  );
});
