import * as SwitchPrimitives from "@radix-ui/react-switch";
import { forwardRef } from "react";
import { apply, tw } from "twind";

type IntentSwitchType =
  | "none"
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | "danger"
  | "info";

const base = apply`relative inline-flex flex-shrink-0 h-6 w-11 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none `;

const colors = {
  none: apply`border(2 transparent) ring(focus:2 focus:offset-2 focus:gray-500) bg-gray-200 [data-state="checked"]:bg-gray-600`,
  primary: apply`border(2 transparent) ring(focus:2 focus:offset-2 focus:blue-500) bg-blue-200 [data-state="checked"]:bg-blue-600`,
  secondary: apply`border(2 transparent) ring(focus:2 focus:offset-2 focus:gray-500) bg-gray-200 [data-state="checked"]:bg-gray-600`,
  success: apply`border(2 transparent) ring(focus:2 focus:offset-2 focus:green-500) bg-green-200 [data-state="checked"]:bg-green-600`,
  warning: apply`border(2 transparent) ring(focus:2 focus:offset-2 focus:yellow-500) bg-yellow-200 [data-state="checked"]:bg-yellow-600`,
  danger: apply`border(2 transparent) ring(focus:2 focus:offset-2 focus:red-500) bg-red-200 [data-state="checked"]:bg-red-600`,
  info: apply`border(2 transparent) ring(focus:2 focus:offset-2 focus:purple-500) bg-purple-200 [data-state="checked"]:bg-purple-600`,
} as const;

const thumbBase = apply`translate-x-0 [data-state="checked"]:translate-x-5 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`;

export type SwitchProps = SwitchPrimitives.SwitchProps & {
  intent?: IntentSwitchType;
  label?: string;
};

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(function Switch(
  { intent = "none", className, label, ...props },
  ref
) {
  const selected = colors[intent];
  return (
    <SwitchPrimitives.Root ref={ref as any} className={tw(base, selected, className)} {...props}>
      <span className={tw`sr-only`}>{label}</span>
      <SwitchPrimitives.Thumb className={tw(thumbBase)} />
    </SwitchPrimitives.Root>
  );
});
