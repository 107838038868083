import React, { FC, useContext } from "react";

export interface ProcessusDelayModalProviderProps {
  sjmoCode: string;
  tableName: string;
  definitionId: string;
  type: string;
  editionType: "rapide" | "apercu";
  selected?: Record<string, any>[];
  advanced: boolean;
  label: string;
  onClose(): void;
  callback(): void;
}

const DelayModalContext = React.createContext<ProcessusDelayModalProviderProps>({
  sjmoCode: "",
  tableName: "",
  definitionId: "",
  type: "",
  editionType: "apercu",
  selected: undefined,
  advanced: false,
  label: "",
  onClose() {},
  callback() {},
});

export function useProcesssuDelayedContext() {
  return useContext(DelayModalContext);
}

export const ProcessusDelayModalProvider: FC<{ value: ProcessusDelayModalProviderProps }> = (
  props,
  children
) => {
  return <DelayModalContext.Provider value={props.value}>{children}</DelayModalContext.Provider>;
};
