import { forwardRef } from "react";
import { tw, apply } from "twind";
import { useIntent } from "../../context/IntentContext";

const base = apply`form-textarea shadow-sm block w-full text-base sm:text-sm rounded-md focus:outline-none`;

const colors = {
  none: apply`focus:ring-gray-500 focus:border-gray-500 border-gray-300 [data-state="checked"]:bg-gray-50)`,
  primary: apply`focus:ring-blue-500 focus:border-blue-500 border-blue-300 [data-state="checked"]:bg-blue-50)`,
  secondary: apply`focus:ring-gray-500 focus:border-gray-500 border-gray-300 [data-state="checked"]:bg-gray-50)`,
  success: apply`focus:ring-green-500 focus:border-green-500 border-green-300 [data-state="checked"]:bg-green-50)`,
  warning: apply`focus:ring-yellow-500 focus:border-yellow-500 border-yellow-300 [data-state="checked"]:bg-yellow-50)`,
  danger: apply`focus:ring-red-500 focus:border-red-500 border-red-300 [data-state="checked"]:bg-red-50)`,
  info: apply`focus:ring-purple-500 focus:border-purple-500 border-purple-300 [data-state="checked"]:bg-purple-50)`,
} as const;

export type TextareaProps = React.ComponentProps<"textarea"> & {
  // weight?: string | number | (string | number)[];
  intent?: keyof typeof colors;
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
  { intent = "none", className, ...props },
  ref
) {
  const selectedIntent = useIntent(intent);
  return (
    <textarea
      ref={ref}
      className={tw(base, selectedIntent && colors[selectedIntent], className)}
      {...props}
    />
  );
});
