import { tw } from "twind";
import { forwardRef } from "react";
import type { ComponentProps } from "react";

export type CardProps = ComponentProps<"div">;
export const Card = forwardRef<HTMLDivElement, CardProps>(function Card(
  { className, ...props },
  ref
) {
  return (
    <div
      ref={ref}
      className={tw("bg-white overflow-hidden shadow rounded-lg", className)}
      {...props}
    />
  );
});
