import React, { useMemo } from "react";
import { apply, tw } from "twind";
import { BREAKPOINTS } from "../utils/breakpoints";

type StackGap = number | (number | null | undefined)[] | undefined;

function createGap(gap: string | number | null | undefined) {
  if (gap === undefined || gap === null) return null;
  return apply(`gap-y-${gap}`);
}

function createGapBreakpoints(gap: StackGap) {
  if (typeof gap === "number" || typeof gap === "string") {
    return [createGap(gap)];
  }

  if (Array.isArray(gap) && gap.length <= BREAKPOINTS.length) {
    return gap.map((g, i) => {
      const gap = createGap(g);

      const b = BREAKPOINTS[i];
      return b == null ? apply(gap) : apply`${b}:${gap}`;
    });
  }

  return [];
}

export type StackProps = React.ComponentProps<"div"> & {
  gap?: StackGap;
};
export const Stack = React.forwardRef<HTMLDivElement, StackProps>(function Stack(
  { gap, className, ...props },
  ref
) {
  const directives = useMemo(() => createGapBreakpoints(gap), [gap]);
  return <div ref={ref} className={tw("flex flex-col", ...directives, className)} {...props} />;
});
