import React, { FC, useContext, useMemo } from "react";
import { apply, tw } from "twind";

import * as TabsPrimitive from "@radix-ui/react-tabs";

const VariantContext = React.createContext<keyof typeof variants>("pill");

const variants = {
  pill: {
    tabListBase: apply`flex space-x-4`,
    tabBase: apply`text(sm gray-500 hover:gray-700) [data-state="active"]:(bg-blue-100 text-blue-700 hover:text-blue-700)  font-medium px-3 py-2  rounded-md`,
    tabContentBase: apply`py-2 px-2`,
  },
} as const;

function useTabVariant() {
  const variant = useContext(VariantContext);

  return useMemo(() => variants[variant], [variant]);
}

export type TabsProps = TabsPrimitive.TabsProps & {
  variant?: keyof typeof variants;
  defaultValue?: string;
  value?: string;
  onValueChange?(value: string): void;
  children: React.ReactNode;
};

export function Tabs({
  variant = "pill",
  defaultValue,
  value,
  onValueChange,
  children,
  ...props
}: TabsProps) {
  return (
    <VariantContext.Provider value={variant}>
      <TabsPrimitive.Root
        defaultValue={defaultValue}
        value={value}
        onValueChange={onValueChange}
        {...props}
      >
        {children}
      </TabsPrimitive.Root>
    </VariantContext.Provider>
  );
}

export type TabsListProps = React.ComponentProps<"div"> & { loop?: boolean };
Tabs.List = React.forwardRef<HTMLDivElement, TabsListProps>(function TabsList(
  { className, ...props },
  ref
) {
  const selected = useTabVariant();
  return (
    <TabsPrimitive.List
      ref={ref as any}
      className={tw(selected.tabListBase, className)}
      {...props}
    />
  );
});

export type TabProps = React.ComponentProps<"button"> & { value: string };
Tabs.Tab = React.forwardRef<HTMLButtonElement, TabProps>(function Tab(
  { className, ...props },
  ref
) {
  const selected = useTabVariant();
  return (
    <TabsPrimitive.Trigger
      ref={ref as any}
      className={tw(selected.tabBase, className)}
      {...props}
    />
  );
});

export type TabContentProps = React.ComponentProps<"div"> & { value: string };
Tabs.Content = React.forwardRef<HTMLDivElement, TabContentProps>(function TabsContent(
  { className, ...props },
  ref
) {
  const selected = useTabVariant();
  return (
    <TabsPrimitive.Content
      ref={ref as any}
      className={tw(selected.tabContentBase, className)}
      {...props}
    />
  );
});
