import {
  formInput,
  formSelect,
  formCheckbox,
  formRadio,
  formTextarea,
  formFile,
  formField,
} from "@twind/forms";

import type { Configuration } from "twind";

export const plugins: Configuration["plugins"] = {
  "form-input": formInput,
  "form-select": formSelect,
  "form-checkbox": formCheckbox,
  "form-radio": formRadio,
  "form-textarea": formTextarea,
  "form-file": formFile,
  "form-field": formField,
  "basis-0": { "flex-basis": "0px" },
  "basis-1": { "flex-basis": "0.25rem" },
  "basis-2": { "flex-basis": "0.5rem" },
  "basis-3": { "flex-basis": "0.75rem" },
  "basis-4": { "flex-basis": "1" },
  "basis-5": { "flex-basis": "1.25rem" },
  "basis-6": { "flex-basis": "1.5rem" },
  "basis-7": { "flex-basis": "1.75rem" },
  "basis-8": { "flex-basis": "2" },
  "basis-9": { "flex-basis": "2.25rem" },
  "basis-10": { "flex-basis": "2.5rem" },
  "basis-11": { "flex-basis": "2.75rem" },
  "basis-12": { "flex-basis": "3" },
  "basis-14": { "flex-basis": "3.5rem" },
  "basis-16": { "flex-basis": "4rem" },
  "basis-20": { "flex-basis": "5rem" },
  "basis-24": { "flex-basis": "6rem" },
  "basis-28": { "flex-basis": "7rem" },
  "basis-32": { "flex-basis": "8rem" },
  "basis-36": { "flex-basis": "9rem" },
  "basis-40": { "flex-basis": "10rem" },
  "basis-44": { "flex-basis": "11rem" },
  "basis-48": { "flex-basis": "12rem" },
  "basis-52": { "flex-basis": "13rem" },
  "basis-56": { "flex-basis": "14rem" },
  "basis-60": { "flex-basis": "15rem" },
  "basis-64": { "flex-basis": "16rem" },
  "basis-72": { "flex-basis": "18rem" },
  "basis-80": { "flex-basis": "20rem" },
  "basis-96": { "flex-basis": "24rem" },
  "basis-auto": { "flex-basis": "auto" },
  "basis-px": { "flex-basis": "1px" },
  "basis-0.5": { "flex-basis": "0.125rem" },
  "basis-1.5": { "flex-basis": "0.375rem" },
  "basis-2.5": { "flex-basis": "0.625rem" },
  "basis-3.5": { "flex-basis": "0.875rem" },
  "basis-1/2": { "flex-basis": "50%" },
  "basis-1/3": { "flex-basis": "33.33333%" },
  "basis-2/3": { "flex-basis": "66.66667%" },
  "basis-1/4": { "flex-basis": "25%" },
  "basis-2/4": { "flex-basis": "50%" },
  "basis-3/4": { "flex-basis": "75%" },
  "basis-1/5": { "flex-basis": "20%" },
  "basis-2/5": { "flex-basis": "40%" },
  "basis-3/5": { "flex-basis": "60%" },
  "basis-4/5": { "flex-basis": "80%" },
  "basis-1/6": { "flex-basis": "16.66667%" },
  "basis-2/6": { "flex-basis": "33.33333%" },
  "basis-3/6": { "flex-basis": "50%" },
  "basis-4/6": { "flex-basis": "66.66667%" },
  "basis-5/6": { "flex-basis": "83.33333%" },
  "basis-1/12": { "flex-basis": "8.33333%" },
  "basis-2/12": { "flex-basis": "16.66667%" },
  "basis-3/12": { "flex-basis": "25%" },
  "basis-4/12": { "flex-basis": "33.33333%" },
  "basis-5/12": { "flex-basis": "41.66667%" },
  "basis-6/12": { "flex-basis": "50%" },
  "basis-7/12": { "flex-basis": "58.33333%" },
  "basis-8/12": { "flex-basis": "66.66667%" },
  "basis-9/12": { "flex-basis": "75%" },
  "basis-10/12": { "flex-basis": "83.33333%" },
  "basis-11/12": { "flex-basis": "91.66667%" },
  "basis-full": { "flex-basis": "100%" },
};

export const twindConfig: Configuration = {
  plugins,
};
