import React, { useMemo, FC } from "react";
import { tw } from "twind";

export interface ServerComponentAst {
  isAST: boolean;
  type: string;
  props?: Record<string, any>;
  children?: ServerComponentAst[] | null | undefined;
}

type ComponentsMap = Record<string, React.ComponentType<any>>;

type AstEntry = ServerComponentAst | ServerComponentAst[] | string | number | null | undefined;

function jsonToReact(map: ComponentsMap, ast: AstEntry, index: number | null = null): any {
  if (ast == null || ast === undefined) return null;
  if (typeof ast == "string" || typeof ast === "number") return ast;

  if (Array.isArray(ast)) {
    return ast.map((child, i) => jsonToReact(map, child, i));
  }

  const { type, props: config, children } = ast;

  const Comp = map[type] || type;

  let renderedChildren = null;
  if (children != null) {
    if (Array.isArray(children)) {
      if (children.length === 1) {
        renderedChildren = jsonToReact(map, children[0]);
      } else {
        renderedChildren = children.map((child, i) => jsonToReact(map, child, i));
      }
    } else {
      renderedChildren = jsonToReact(map, children);
    }
  }

  const key = config?.key ?? index ?? undefined;

  const keys = config != null && config != undefined ? Object.keys(config) : [];

  const props: Record<string, any> = { ...config, key };

  for (let key of keys) {
    if (typeof props[key] === "object" && props[key].isAST === true) {
      props[key] = jsonToReact(map, props[key]);
    }
  }

  if (renderedChildren != null) {
    props["className"] = tw(props["className"]);
    if (Array.isArray(renderedChildren)) {
      return React.createElement(Comp, props, ...renderedChildren);
    } else {
      return React.createElement(Comp, props, renderedChildren);
    }
  } else {
    props["className"] = tw(props["className"]);
    return React.createElement(Comp, props);
  }
}

export type ServerComponentProps = {
  components: ComponentsMap;
  ast: ServerComponentAst | ServerComponentAst[];
};
export function ServerComponent(props: ServerComponentProps) {
  const renderedAst = useMemo(() => {
    return jsonToReact(props.components, props.ast);
  }, [props.components, props.ast]);

  return <React.Fragment>{renderedAst}</React.Fragment>;
}
