import { forwardRef } from "react";
import { tw, apply } from "twind";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { IntentProvider, useIntent } from "../../context/IntentContext";
import { useId } from "../../utils/useId";
import { faCircle, faDotCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const colors = {
  none: apply`focus:ring-gray-500 focus:border-gray-500 border-gray-300 text-gray-600 ring(focus:1 focus:gray-500 focus:offset-1)`,
  primary: apply`focus:ring-blue-500 focus:border-blue-500 border-blue-300 text-blue-600 ring(focus:1 focus:blue-500 focus:offset-1)`,
  secondary: apply`focus:ring-gray-500 focus:border-gray-500 border-gray-300 text-gray-600 ring(focus:1 focus:gray-500 focus:offset-1)`,
  success: apply`focus:ring-green-500 focus:border-green-500 border-green-300 text-green-600 ring(focus:1 focus:green-500 focus:offset-1)`,
  warning: apply`focus:ring-yellow-500 focus:border-yellow-500 border-yellow-300 text-yellow-600 ring(focus:1 focus:yellow-500 focus:offset-1)`,
  danger: apply`focus:ring-red-500 focus:border-red-500 border-red-300 text-red-600 ring(focus:1 focus:red-500 focus:offset-1)`,
  info: apply`focus:ring-purple-500 focus:border-purple-500 border-purple-300 text-purple-600 ring(focus:1 focus:purple-500 focus:offset-1)`,
} as const;

export type RadioProps = RadioGroup.RadioGroupProps & { intent?: keyof typeof colors };
export function Radio({ className, intent, ...props }: RadioProps) {
  const selectedIntent = useIntent(intent);
  return (
    <IntentProvider intent={selectedIntent}>
      <RadioGroup.Root className={tw("space-y-4", className)} {...props} />
    </IntentProvider>
  );
}

const base = apply`form-radio h-4 w-4 bg-white rounded-full border-gray-300 border-1 focus:outline-none`;

export type RadioItemProps = RadioGroup.RadioGroupItemProps & {};
Radio.Item = forwardRef<HTMLInputElement, RadioItemProps>(function RadioItem(
  { id, className, children, ...props },
  ref
) {
  const htmlFor = useId(id);
  const intent = useIntent() ?? "none";
  return (
    <div className={tw("flex items-center")}>
      <RadioGroup.Item
        ref={ref as any}
        id={htmlFor}
        className={tw(base, colors[intent], className)}
        {...props}
      >
        <RadioGroup.Indicator>
          <FontAwesomeIcon icon={faCircle} transform="shrink-8 up-5 left-1" />
        </RadioGroup.Indicator>
      </RadioGroup.Item>
      <label htmlFor={htmlFor} className={tw("ml-2 block text-sm font-medium text-gray-700")}>
        {children}
      </label>
    </div>
  );
});

Radio.BaseItem = forwardRef<HTMLInputElement, RadioItemProps>(function RadioItem(
  { id, className, children, ...props },
  ref
) {
  const htmlFor = useId(id);
  const intent = useIntent() ?? "none";
  return (
    <div className={tw("flex items-center")}>
      <RadioGroup.Item
        ref={ref as any}
        id={htmlFor}
        className={tw(base, colors[intent], className)}
        {...props}
      >
        {children}
      </RadioGroup.Item>
    </div>
  );
});

export type RadioBaseIndicatorProps = RadioGroup.RadioGroupIndicatorProps & {};
Radio.BaseIndicator = forwardRef<HTMLSpanElement, RadioBaseIndicatorProps>(function RadioItem(
  { id, className, children, ...props },
  ref
) {
  return (
    <RadioGroup.Indicator {...props} ref={ref}>
      <FontAwesomeIcon icon={faCircle} transform="shrink-8 up-5 left-1" />
    </RadioGroup.Indicator>
  );
});
