import { useContext, forwardRef, createContext } from "react";
import { apply, tw, Directive, CSSRules } from "twind";

const RingContext = createContext<Directive<CSSRules> | undefined>(undefined);

const base = apply`inline-block`;

const sizes = {
  xs: apply`h-6 w-6`,
  sm: apply`h-8 w-8`,
  md: apply`h-10 w-10`,
  lg: apply`h-12 w-12`,
  xl: apply`h-14 w-14`,
} as const;

const rounded = {
  md: apply`rounded-md`,
  full: apply`rounded-full`,
};

export type AvatarProps = React.ComponentProps<"img"> & {
  size?: keyof typeof sizes;
  rounded?: keyof typeof rounded;
};
export const Avatar = forwardRef<HTMLImageElement, AvatarProps>(function Avatar(
  { size = "md", rounded: r = "full", className, ...props },
  ref
) {
  const ring = useContext(RingContext);
  return (
    <img ref={ref} className={tw(base, sizes[size], rounded[r], ring, className)} {...props} />
  );
});

const stackedBase = apply`flex overflow-hidden`;

const stackedGap = {
  sm: apply`-space-x-1`,
  md: apply`-space-x-2`,
} as const;

export type StackedAvatarProps = React.ComponentProps<"div"> & {
  gap?: keyof typeof stackedGap;
};

const stackedRing = apply`ring(2 white focus:blue-100)`;

export const StackedAvatar = forwardRef<HTMLDivElement, StackedAvatarProps>(function StackedAvatar(
  { gap = "sm", className, children, ...props },
  ref
) {
  return (
    <RingContext.Provider value={stackedRing}>
      <div ref={ref} className={tw(stackedBase, stackedGap[gap], className)} {...props}>
        {children}
      </div>
    </RingContext.Provider>
  );
});
