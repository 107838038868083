import { tw, apply } from "twind";
import * as ToolbarPrimitive from "@radix-ui/react-toolbar";
import { forwardRef } from "react";

export type ToolbarProps = ToolbarPrimitive.ToolbarProps & {};
export function Toolbar({ className, children, ...props }: ToolbarProps) {
  return (
    <ToolbarPrimitive.Root
      // ref={ref}
      className={tw("relative z-0 inline-flex justify-center", className)}
      {...props}
    >
      {children}
    </ToolbarPrimitive.Root>
  );
}

//  relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500
const baseButton = apply`not-first-child:-ml-px first-child:rounded-l-md last-child:rounded-r-md relative
  inline-flex items-center px-4 py-2
  border border-gray-300 bg-white
  text-sm font-medium text-gray-700
  hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500
  [data-state="on"]:bg-gray-100
`;
export type ToolbarButtonProps = ToolbarPrimitive.ToolbarButtonProps & {};
Toolbar.Button = forwardRef<HTMLButtonElement, ToolbarButtonProps>(function ToolbarButton(
  { className, ...props },
  ref
) {
  return <ToolbarPrimitive.Button ref={ref} className={tw(baseButton, className)} {...props} />;
});

type ToolbarLinkProps = ToolbarPrimitive.ToolbarLinkProps & {};
Toolbar.Link = forwardRef<HTMLAnchorElement, ToolbarLinkProps>(function ToolbarLink(
  { className, ...props },
  ref
) {
  return (
    <ToolbarPrimitive.Link
      ref={ref}
      className={tw("inline-flex items-center", className)}
      {...props}
    />
  );
});

export type ToolbarToggleGroupProps = ToolbarPrimitive.ToggleGroupProps & {};
Toolbar.ToggleGroup = forwardRef<HTMLDivElement, ToolbarToggleGroupProps>(
  function ToolbarToggleGroup({ className, ...props }, ref) {
    return <ToolbarPrimitive.ToggleGroup ref={ref} className={tw(className)} {...props} />;
  }
);

export type ToolbarToggleItemProps = ToolbarPrimitive.ToolbarToggleItemProps & {};
Toolbar.ToggleItem = forwardRef<HTMLButtonElement, ToolbarToggleItemProps>(
  function ToolbarToggleItem({ className, ...props }, ref) {
    return (
      <ToolbarPrimitive.ToggleItem ref={ref} className={tw(baseButton, className)} {...props} />
    );
  }
);

export type ToolbarSeparatorProps = ToolbarPrimitive.ToolbarSeparatorProps & {};
Toolbar.Separator = forwardRef<HTMLDivElement, ToolbarSeparatorProps>(function ToolbarSeparator(
  { className, ...props },
  ref
) {
  return (
    <ToolbarPrimitive.Separator
      ref={ref}
      className={tw(className, "w-px bg-gray-300 my-0 mx-3 h-[80%]")}
      {...props}
    />
  );
});
