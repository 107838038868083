import { forwardRef } from "react";
import type { ComponentProps } from "react";
import { tw } from "twind";

export type ContainerProps = ComponentProps<"div">;
export const Container = forwardRef<HTMLDivElement, ContainerProps>(function Container(
  { className, children, ...props },
  ref
) {
  return (
    <div ref={ref} className={tw("max-w-7xl mx-auto px-4 sm:px-6 lg:px-8", className)} {...props}>
      <div className={tw`max-w-3xl mx-auto`}>{children}</div>
    </div>
  );
});
