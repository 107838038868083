import { tw } from "twind";

export type DividerProps = { label?: string };
export function Divider({ label }: DividerProps) {
  return (
    <div className={tw("relative")}>
      <div className={tw("absolute inset-0 flex items-center")} aria-hidden="true">
        <div className={tw("w-full border-t border-gray-300")}></div>
      </div>
      {label && (
        <div className={tw("relative flex justify-center")}>
          <span className={tw("px-2 bg-white text-sm text-gray-500")}>{label}</span>
        </div>
      )}
    </div>
  );
}
