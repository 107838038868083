import { forwardRef } from "react";
import { apply, tw } from "twind";
import { css, keyframes } from "twind/css";
import { Columns } from "../layouts/Columns";

const bouncing = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const bounceDelay = css({
  "> div": {
    animation: `1.4s infinite ease-in-out both`,
    animationName: bouncing,
  },
  "> div:nth-child(1)": { animationDelay: "-.32s" },
  "> div:nth-child(3)": { animationDelay: "-.16s" },
});

const bounce = apply`w-[18px] h-[18px] bg-gray-300 rounded-full inline-block`;

export type LoaderProps = React.ComponentProps<"div">;
export const Loader = forwardRef<HTMLDivElement, React.ComponentProps<"div">>(function Loader(
  { className, ...props },
  ref
) {
  return (
    <Columns ref={ref as any} gap={0.5} className={tw(bounceDelay, className)} {...props}>
      <div className={tw(bounce)} />
      <div className={tw(bounce)} />
      <div className={tw(bounce)} />
    </Columns>
  );
});
