import { useState, useContext, CSSProperties, useMemo } from "react";
import type { ComponentProps, ReactNode, MouseEvent } from "react";

import {
  useRegisterProcessus,
  useInfoProcessus,
  useStableProcessusId,
} from "../logic/processusManager";
import { ProcessusConfirmationModalProvider } from "../logic/ProcessusConfirmationModalProvider";
import { ProcessusContext } from "../logic/ProcessusProvider";
import { getDimensionFromEvent } from "../logic/component.utils";
import { ButtonGroup, Button, ButtonLink } from "../atoms";
import { ProcessusDelayModalProvider } from "../logic/ProcessusDelayModalProvider";
import { faClock, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tw } from "twind";
import { css } from "twind/css";
import { Column, Columns, Stack } from "..";

export enum ProcessusAffectation {
  GLOBAL,
  ROLE,
  USER,
  MODULE,
  NONE,
}

interface ProcessusDefinitionShared {
  id: string;
  label: string;
  affectation: ProcessusAffectation;
  needEntity: boolean;
}

export interface ProcessusDefinitionTraitement extends ProcessusDefinitionShared {
  type: "traitement";
  isAdvanced: boolean;
  forAll: boolean;
  isDifferable: boolean;
}

export interface ProcessusDefinitionEdition extends ProcessusDefinitionShared {
  type: "edition";
  isAdvanced: boolean;
  apercu: boolean;
  rapide: boolean;
  isDifferable: boolean;
}

export interface ProcessusDefinitionNavigation extends ProcessusDefinitionShared {
  type: "navigation";
}

export type ProcessusDefinition =
  | ProcessusDefinitionTraitement
  | ProcessusDefinitionEdition
  | ProcessusDefinitionNavigation;

function ProcessusLoading(props: { id: string }) {
  const [processusState] = useInfoProcessus(props.id);

  return !processusState.matches("done") &&
    !processusState.matches("wait") &&
    !processusState.matches("error") ? (
    <span>
      <FontAwesomeIcon icon={faSpinner as any} spin />
    </span>
  ) : null;
}

export interface ProcessusLinkProps {
  definition: ProcessusDefinition;
  canDelayProcess?: Boolean;
  className?: string;
  style?: CSSProperties;
  confirmationModal: ReactNode;
  delayedModal: ReactNode;
  openAdvanced(
    sjmoCode: string,
    definitionId: string,
    definitionType: "traitement" | "edition",
    selected: Record<string, any>[],
    editionType: "rapide" | "apercu",
    onAfterSaveContext: () => void
  ): void;
  onSave(sjmoCode: string): void;
  t(key: any): any;
  track?(code: string): void;
}

const EMPTY_CALLBACK = () => {};

export function ProcessusLink({
  definition,
  children,
  canDelayProcess,
  openAdvanced,
  track,
  t,
  onSave,
  confirmationModal,
  delayedModal,
  ...restProps
}: ComponentProps<"a"> & { disabled?: boolean } & ProcessusLinkProps) {
  const context = useContext(ProcessusContext);
  const [managerState, { register: registerProcessus, reset: resetProcessus }] =
    useRegisterProcessus();

  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [position, setPosition] = useState<{ x: number; y: number } | null>(null);

  if (context === null) {
    throw new Error("cannot use ProcessLink without ProcessusContext");
  }

  const stableProcessusId = useStableProcessusId(definition.id, context.selected ?? [], undefined);

  const machineId = managerState.context.info[stableProcessusId];
  const currentProcessusMachine = managerState.context.processus[machineId ?? ""];

  const hasProcessusStateSpawned =
    currentProcessusMachine !== undefined &&
    !currentProcessusMachine.getSnapshot().matches("error") &&
    !currentProcessusMachine.getSnapshot().matches("done");

  const editionDefault = useMemo(() => {
    let editionDefault: "rapide" | "apercu" | undefined = undefined;
    if (definition.type === "edition") {
      if (definition.apercu) editionDefault = "apercu";
      if (definition.rapide) editionDefault = "rapide";
    }
    return editionDefault;
  }, [
    definition.type === "edition" && definition.apercu,
    definition.type === "edition" && definition.rapide,
    definition.type,
  ]);

  const usableContext = context;

  const isDisabled =
    definition.needEntity && (!usableContext.selected || usableContext.selected.length === 0);
  function launch(editionType?: "rapide" | "apercu") {
    if (isDisabled) return;

    if (currentProcessusMachine !== undefined) {
      const snapshot = currentProcessusMachine.getSnapshot();

      if (snapshot.matches("error")) {
        resetProcessus(definition.id, usableContext.selected);
      }
    }

    if (
      (definition.type === "traitement" && definition.isAdvanced) ||
      (definition.type === "edition" && definition.isAdvanced)
    ) {
      openAdvanced(
        usableContext.sjmoCode,
        definition.id,
        definition.type,
        usableContext.selected as any,
        editionType ?? "rapide",
        usableContext.onAfterSaveProcess || EMPTY_CALLBACK
      );
      return;
    }

    registerProcessus(
      {
        module: usableContext.sjmoCode,
        compositeID: definition.id,
        type: definition.type,
        selected: usableContext.selected ?? [],
        // il faut rajouter la saisie de l'heure d'execution
        executeAt: undefined,
        editionType: editionType ?? editionDefault,
        label: definition.label,
      },
      usableContext.onAfterSaveProcess
    );
  }

  function save() {
    track && track("processus::modal::confirm");
    setShowConfirmModal(false);
    onSave(usableContext.sjmoCode);
  }

  function onClick(e: MouseEvent<HTMLAnchorElement>) {
    if (restProps.disabled || isDisabled) return;

    const editionType = (e.target as HTMLElement)?.dataset.editionType as
      | "rapide"
      | "apercu"
      | undefined;

    if (usableContext.isDirty) {
      setShowConfirmModal(true);
      setPosition(getDimensionFromEvent(e as any));
    } else {
      launch(editionType);
    }
  }

  return (
    <>
      <Columns gap={0}>
        <Column width="full">
          <a
            {...restProps}
            // {...(buttonProps as any)}
            onClick={onClick}
            // ref={containerRef}
            className={tw(
              "w-full",
              css({
                "&:hover > .processmenu-action": { display: "block" },
              }),
              restProps.className
            )}
            style={restProps.style}
          >
            <div className={tw("w-full")}>{children ?? definition.label}</div>
            {definition.type === "edition" && definition.apercu && definition.rapide && (
              <Columns gap={0} className={tw("hidden processmenu-action")}>
                <Column width="1/2">
                  <Button intent="primary" data-edition-type="apercu" disabled={!definition.apercu}>
                    {t("comet_apercu")}
                  </Button>
                </Column>
                <Column width="1/2">
                  <Button intent="info" data-edition-type="rapide" disabled={!definition.rapide}>
                    {t("comet_rapide")}
                  </Button>
                </Column>
              </Columns>
            )}
          </a>
        </Column>

        <Column width="narrow">
          {definition.type !== "navigation" && definition.isDifferable && canDelayProcess && (
            <Button variant="text" onClick={() => setShowCalendarModal(true)}>
              <FontAwesomeIcon icon={faClock as any} />
            </Button>
          )}
        </Column>
        <Column width="narrow">
          {hasProcessusStateSpawned && <ProcessusLoading id={stableProcessusId} />}
        </Column>
      </Columns>

      {showCalendarModal && (
        <ProcessusDelayModalProvider
          value={{
            sjmoCode: usableContext.sjmoCode,
            tableName: usableContext.tableName,
            definitionId: definition.id,
            type: definition.type,
            editionType: editionDefault || "rapide",
            selected: usableContext.selected ?? [],
            advanced:
              (definition.type === "traitement" && definition.isAdvanced) ||
              (definition.type === "edition" && definition.isAdvanced),
            label: definition.label,
            onClose: () => setShowCalendarModal(false),
            callback: usableContext.onAfterSaveProcess || EMPTY_CALLBACK,
          }}
        >
          <>{delayedModal}</>
        </ProcessusDelayModalProvider>
      )}
      {showConfirmModal && position && (
        <ProcessusConfirmationModalProvider
          value={{
            definitionId: definition.id,
            launchProcess: launch,
            save: save,
            close: () => {
              setShowConfirmModal(false);
              // track("processus::modal::cancel");
            },
            top: position.x,
            left: position.y,
          }}
        >
          {confirmationModal}
        </ProcessusConfirmationModalProvider>
      )}
    </>
  );
}
