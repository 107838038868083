import React, { useMemo } from "react";
import { apply, CSSRules, Directive, tw } from "twind";
import { BREAKPOINTS, applyBreakpoint, useApplyBreakpoints } from "../utils/breakpoints";

const templateColumns = {
  "1": apply`grid-cols-1`,
  "2": apply`grid-cols-2`,
  "3": apply`grid-cols-3`,
  "4": apply`grid-cols-4`,
  "5": apply`grid-cols-5`,
  "6": apply`grid-cols-6`,
  "7": apply`grid-cols-7`,
  "8": apply`grid-cols-8`,
  "9": apply`grid-cols-9`,
  "10": apply`grid-cols-10`,
  "11": apply`grid-cols-11`,
  "12": apply`grid-cols-12`,
  none: apply`grid-cols-none`,
} as const;
type TemplateColumnsType = keyof typeof templateColumns;

const templateRows = {
  "1": apply`grid-rows-1`,
  "2": apply`grid-rows-2`,
  "3": apply`grid-rows-3`,
  "4": apply`grid-rows-4`,
  "5": apply`grid-rows-5`,
  "6": apply`grid-rows-6`,
  none: apply`grid-rows-none`,
} as const;
type TemplateRowsType = keyof typeof templateRows;

const gridFlows = {
  row: apply`grid-flow-row`,
  column: apply`grid-flow-col`,
  rowDense: apply`grid-flow-row-dense`,
  columnDense: apply`grid-flow-col-dense`,
} as const;
type GridFlowsType = keyof typeof gridFlows;

export type GridProps = React.ComponentProps<"div"> & {
  gridCols?: TemplateColumnsType | TemplateColumnsType[];
  gridRows?: TemplateRowsType | TemplateRowsType[];
  gridFlow?: GridFlowsType | GridFlowsType[];
  rowGap?: number | (number | null)[];
  colGap?: number | (number | null)[];
};

export const Grid = React.forwardRef<HTMLDivElement, GridProps>(function Grid(
  { colGap = 0, rowGap = 0, gridCols, gridRows, gridFlow, className, ...props },
  ref
) {
  const colGapDirectives = useMemo(() => {
    if (!colGap) return [];
    if (Array.isArray(colGap)) {
      return colGap.map((g, i) => {
        const b = BREAKPOINTS[i];
        return b == null ? apply(g) : apply(`${b}:${g}`);
      });
    }
    return [apply(`gap-y-${colGap}`)];
  }, [colGap]);

  const rowGapDirectives = useMemo(() => {
    if (!rowGap) return [];
    if (Array.isArray(rowGap)) {
      return rowGap.map((g, i) => {
        const b = BREAKPOINTS[i];
        return b == null ? apply(g) : apply(`${b}:${g}`);
      });
    }
    return [apply(`gap-x-${rowGap}`)];
  }, [rowGap]);

  const gridColsDirectives = useApplyBreakpoints(gridCols, templateColumns);
  const gridRowsDirectives = useApplyBreakpoints(gridRows, templateRows);
  const gridFlowDirectives = useApplyBreakpoints(gridFlow, gridFlows);

  return (
    <div
      ref={ref}
      className={tw(
        "grid",
        ...colGapDirectives,
        ...rowGapDirectives,
        ...gridColsDirectives,
        ...gridRowsDirectives,
        ...gridFlowDirectives,
        className
      )}
      {...props}
    />
  );
});

type GridElementProps = {
  colSpan?: ColumnSpansType | ColumnSpansType[];
  colStart?: ColumnStartsType | ColumnStartsType[];
  colEnd?: ColumnEndsType | ColumnEndsType[];
  rowSpan?: RowSpansType | RowSpansType[];
  rowStart?: RowStartsType | RowStartsType[];
  rowEnd?: RowEndsType | RowEndsType[];
  className?: string | Directive<CSSRules>;
};

export function gridPos({
  colSpan,
  colStart,
  colEnd,
  rowSpan,
  rowStart,
  rowEnd,
  className,
}: GridElementProps) {
  const colDirectives = applyBreakpoint(colSpan, columnSpans);
  const rowDirectives = applyBreakpoint(rowSpan, rowSpans);
  const colStartDirectives = applyBreakpoint(colStart, columnStarts);
  const rowStartDirectives = applyBreakpoint(rowStart, rowStarts);
  const colEndDirectives = applyBreakpoint(colEnd, columnEnds);
  const rowEndDirectives = applyBreakpoint(rowEnd, rowEnds);

  return apply(
    ...colDirectives,
    ...rowDirectives,
    ...colStartDirectives,
    ...rowStartDirectives,
    ...colEndDirectives,
    ...rowEndDirectives,
    className
  );
}

const columnSpans = {
  auto: apply`col-auto`,
  1: apply`col-span-1`,
  2: apply`col-span-2`,
  3: apply`col-span-3`,
  4: apply`col-span-4`,
  5: apply`col-span-5`,
  6: apply`col-span-6`,
  7: apply`col-span-7`,
  8: apply`col-span-8`,
  9: apply`col-span-9`,
  10: apply`col-span-10`,
  11: apply`col-span-11`,
  12: apply`col-span-12`,
  full: apply`col-span-full`,
} as const;
type ColumnSpansType = keyof typeof columnSpans;

const columnStarts = {
  auto: apply`col-start-auto`,
  1: apply`col-start-1`,
  2: apply`col-start-2`,
  3: apply`col-start-3`,
  4: apply`col-start-4`,
  5: apply`col-start-5`,
  6: apply`col-start-6`,
  7: apply`col-start-7`,
  8: apply`col-start-8`,
  9: apply`col-start-9`,
  10: apply`col-start-10`,
  11: apply`col-start-11`,
  12: apply`col-start-12`,
  13: apply`col-start-13`,
} as const;
type ColumnStartsType = keyof typeof columnStarts;

const columnEnds = {
  auto: apply`col-end-auto`,
  1: apply`col-end-1`,
  2: apply`col-end-2`,
  3: apply`col-end-3`,
  4: apply`col-end-4`,
  5: apply`col-end-5`,
  6: apply`col-end-6`,
  7: apply`col-end-7`,
  8: apply`col-end-8`,
  9: apply`col-end-9`,
  10: apply`col-end-10`,
  11: apply`col-end-11`,
  12: apply`col-end-12`,
  13: apply`col-end-13`,
} as const;
type ColumnEndsType = keyof typeof columnEnds;

const rowSpans = {
  auto: apply`row-auto`,
  1: apply`row-span-1`,
  2: apply`row-span-2`,
  3: apply`row-span-3`,
  4: apply`row-span-4`,
  5: apply`row-span-5`,
  6: apply`row-span-6`,
  full: apply`row-span-full`,
} as const;
type RowSpansType = keyof typeof rowSpans;

const rowStarts = {
  auto: apply`row-start-auto`,
  1: apply`row-start-1`,
  2: apply`row-start-2`,
  3: apply`row-start-3`,
  4: apply`row-start-4`,
  5: apply`row-start-5`,
  6: apply`row-start-6`,
  7: apply`row-start-7`,
} as const;
type RowStartsType = keyof typeof rowStarts;

const rowEnds = {
  auto: apply`row-end-auto`,
  1: apply`row-end-1`,
  2: apply`row-end-2`,
  3: apply`row-end-3`,
  4: apply`row-end-4`,
  5: apply`row-end-5`,
  6: apply`row-end-6`,
  7: apply`row-end-7`,
} as const;
type RowEndsType = keyof typeof rowEnds;
