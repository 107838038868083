import type * as React from "react";
import { tw, theme } from "twind";

import * as TooltipPrimitives from "@radix-ui/react-tooltip";

export type TooltipProviderProps = {
  delayDuration?: number;
  skipDelayDuration?: number;
  children: React.ReactNode;
};

export function TooltipProvider({
  delayDuration,
  skipDelayDuration,
  children,
}: TooltipProviderProps) {
  return (
    <TooltipPrimitives.Provider delayDuration={delayDuration} skipDelayDuration={skipDelayDuration}>
      {children}
    </TooltipPrimitives.Provider>
  );
}

export type TooltipProps = {
  label: React.ReactNode;
  withArrow?: boolean;
  side?: TooltipPrimitives.TooltipContentProps["side"];
  children: React.ReactNode;
};
export function Tooltip({ children, label, side, withArrow = false }: TooltipProps) {
  return (
    <TooltipPrimitives.Root>
      <TooltipPrimitives.Trigger asChild>{children}</TooltipPrimitives.Trigger>

      <TooltipPrimitives.Content
        className={tw`bg-gray-50 border(& gray-300) py-1 px-2 rounded`}
        side={side}
        sideOffset={5}
      >
        {withArrow === true && (
          // TODO: fix this, color are not working
          <TooltipPrimitives.Arrow className={tw`fill-current bg-gray-100 text-gray-100`} />
        )}
        {label}
      </TooltipPrimitives.Content>
    </TooltipPrimitives.Root>
  );
}
