import { apply, tw } from "twind";
import { keyframes, animation } from "twind/css";
import * as AlertPrimitive from "@radix-ui/react-alert-dialog";

import {
  faExclamationCircle,
  faTimesCircle,
  faCheckCircle,
  faInfoCircle,
  faSparkles,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IntentProvider, useIntent } from "../context/IntentContext";
import { forwardRef } from "react";

type IntentType = "none" | "primary" | "success" | "warning" | "danger" | "info";

const baseColors = {
  none: apply`bg-gray-50`,
  primary: apply`bg-blue-50`,
  success: apply`bg-green-50`,
  warning: apply`bg-yellow-50`,
  danger: apply`bg-red-50`,
  info: apply`bg-purple-50`,
} as const;

const titleColors = {
  none: apply`text-gray-800`,
  primary: apply`text-blue-800`,
  success: apply`text-green-800`,
  warning: apply`text-yellow-800`,
  danger: apply`text-red-800`,
  info: apply`text-purple-800`,
} as const;

const descColors = {
  none: apply`text-gray-700`,
  primary: apply`text-blue-700`,
  success: apply`text-green-700`,
  warning: apply`text-yellow-700`,
  danger: apply`text-red-700`,
  info: apply`text-purple-700`,
} as const;

const iconColors = {
  none: apply`text-gray-400`,
  primary: apply`text-blue-400`,
  success: apply`text-green-400`,
  warning: apply`text-yellow-400`,
  danger: apply`text-red-400`,
  info: apply`text-purple-400`,
} as const;

const intentIcons = {
  none: faInfoCircle,
  primary: faInfoCircle,
  success: faCheckCircle,
  warning: faExclamationCircle,
  danger: faTimesCircle,
  info: faSparkles,
} as const;

type AlertIntent = keyof typeof baseColors;

export type AlertProps = {
  title: React.ReactNode;
  actions?: React.ReactNode;
  intent?: AlertIntent;
  className?: string;
  onClose?(): void;
};

export function Alert({
  title,
  actions,
  intent = "none",
  onClose,
  className,
  children,
}: React.PropsWithChildren<AlertProps>) {
  return (
    <div className={tw(`rounded-md p-4`, baseColors[intent], className)}>
      <div className={tw`flex`}>
        <div className={tw`flex-shrink-0`}>
          <FontAwesomeIcon icon={intentIcons[intent]} className={tw(iconColors[intent])} />
        </div>
        {/* IntentProvider permet de choisir passer l'intent au composants avec intent children que l'on ne gère pas nous même */}
        <IntentProvider intent={intent}>
          <div className={tw`ml-3`}>
            <h3 className={tw(`text-sm font-medium`, titleColors[intent])}>{title}</h3>
            {children && <div className={tw(`mt-2 text-sm`, descColors[intent])}>{children}</div>}

            {actions && (
              <div className={tw("mt-4")}>
                <div className={tw("-mx-2 -my-1.5 flex gap-x-3")}>{actions}</div>
              </div>
            )}
          </div>
          {onClose && (
            <div className={tw`ml-auto pl-3`}>
              <div className={tw`-mx-1.5 -my-1.5`}>
                <Close onClose={onClose} />
              </div>
            </div>
          )}
        </IntentProvider>
      </div>
    </div>
  );
}

const baseClose = apply`inline-flex rounded-md p-1.5 focus:outline-none ring(focus:2 focus:offset-2)`;

const closeColors = {
  none: apply`bg(gray-50 hover:gray-100) text-gray-500 focus:ring-offset-gray-50 focus:ring-gray-600`,
  primary: apply`bg(blue-50 hover:blue-100) text-blue-500 focus:ring-offset-blue-50 focus:ring-blue-600`,
  secondary: apply`bg(gray-50 hover:gray-100) text-gray-500 focus:ring-offset-gray-50 focus:ring-gray-600`,
  success: apply`bg(green-50 hover:green-100) text-green-500 focus:ring-offset-green-50 focus:ring-green-600`,
  warning: apply`bg(yellow-50 hover:yellow-100) text-yellow-500 focus:ring-offset-yellow-50 focus:ring-yellow-600`,
  danger: apply`bg(red-50 hover:red-100) text-red-500 focus:ring-offset-red-50 focus:ring-red-600`,
  info: apply`bg(purple-50 hover:purple-100) text-purple-500 focus:ring-offset-purple-50 focus:ring-purple-600`,
} as const;

function Close({ onClose }: { onClose(): void }) {
  const intent = useIntent();
  return (
    <button className={tw(baseClose, intent && closeColors[intent])} onClick={onClose}>
      <span className={tw`sr-only`}>Dismiss</span>
      <svg
        className={tw`h-5 w-5`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  );
}

export type AlertDialogProps = {
  trigger: React.ReactNode;
  title: React.ReactNode;
  cancel: React.ReactNode;
  confirm: React.ReactNode;
  intent?: AlertProps["intent"];
  children?: React.ReactNode;
};
export function AlertDialog({
  trigger,
  children,
  title,
  cancel,
  confirm,
  intent = "none",
}: AlertDialogProps) {
  return (
    <AlertPrimitive.Root>
      <AlertPrimitive.Trigger asChild>{trigger}</AlertPrimitive.Trigger>
      <AlertPrimitive.Portal>
        <AlertPrimitive.Content>
          <div className={tw`max-w-prose absolute top-1/2 left-1/2`}>
            <Alert
              intent={intent}
              title={<AlertPrimitive.Title>{title}</AlertPrimitive.Title>}
              actions={
                <>
                  <AlertPrimitive.Cancel asChild>{cancel}</AlertPrimitive.Cancel>
                  <AlertPrimitive.Action asChild>{confirm}</AlertPrimitive.Action>
                </>
              }
            >
              <AlertPrimitive.AlertDialogDescription>
                {children}
              </AlertPrimitive.AlertDialogDescription>
            </Alert>
          </div>
        </AlertPrimitive.Content>
      </AlertPrimitive.Portal>
    </AlertPrimitive.Root>
  );
}

const baseAlertButton = apply`px-2 py-1.5 rounded-md text-sm font-medium focus:outline-none ring(focus:2 focus:offset-2)`;

const alertButtonColors = {
  none: apply`bg(gray-50 hover:gray-100) text-gray-800 focus:ring-offset-gray-50 focus:ring-gray-600`,
  primary: apply`bg(blue-50 hover:blue-100) text-blue-800 focus:ring-offset-blue-50 focus:ring-blue-600`,
  secondary: apply`bg(gray-50 hover:gray-100) text-gray-800 focus:ring-offset-gray-50 focus:ring-gray-600`,
  success: apply`bg(green-50 hover:green-100) text-green-800 focus:ring-offset-green-50 focus:ring-green-600`,
  warning: apply`bg(yellow-50 hover:yellow-100) text-yellow-800 focus:ring-offset-yellow-50 focus:ring-yellow-600`,
  danger: apply`bg(red-50 hover:red-100) text-red-800 focus:ring-offset-red-50 focus:ring-red-600`,
  info: apply`bg(purple-50 hover:purple-100) text-purple-800 focus:ring-offset-purple-50 focus:ring-purple-600`,
};

type AlertButtonProps = React.ComponentProps<"button"> & { intent?: IntentType };
AlertDialog.Button = forwardRef<HTMLButtonElement, AlertButtonProps>(function AlertButton(
  { intent: parentIntent, className, ...props },
  ref
) {
  const intent = useIntent(parentIntent);
  return (
    <button
      ref={ref}
      className={tw(baseAlertButton, intent && alertButtonColors[intent], className)}
      {...props}
    />
  );
});
