import { forwardRef } from "react";
import { tw, apply } from "twind";

const base = apply`block text-sm font-medium text-gray-700`;

export type LabelProps = React.ComponentProps<"label">;
export const Label = forwardRef<HTMLLabelElement, LabelProps>(function Label(
  { className, ...props },
  ref
) {
  return <label ref={ref} className={tw(base, className)} {...props} />;
});
