import React, { FC, useCallback, useContext, useMemo, useState } from "react";

import { Label } from "./label";
import { Notice } from "../Notice";
import type { IntentType } from "./types";
import type { validationType } from "./formFields";

import { IntentProvider } from "../../context/IntentContext";
import { tw } from "twind";
import { Stack } from "../../layouts/Stack";
import { useId } from "../../utils/useId";

export interface ValidationResult<T> {
  entity: T;
  state: ValidationState;
}

export interface ValidationState {
  intent: IntentType;
  message: string;
}

export type FormFieldProps = {
  id?: string;
  name?: string;
  label: React.ReactNode;
  help?: React.ReactNode;
  required?: boolean;
  className?: string;
  children?: React.ReactNode;
};

export function FormField({
  id,
  name,
  label,
  help,
  required,
  className,
  children,
}: FormFieldProps) {
  const htmlFor = useId(id);
  const { intent, helpMessage } = useFormField(name ?? "__no_name_provided", "NONE");

  return (
    <div className={className}>
      <IntentProvider intent={intent}>
        <Label htmlFor={htmlFor}>
          {label}
          {required && <span className={tw("px-1 text-red-800")}>*</span>}
        </Label>
        <Stack gap={2} className={tw("mt-2")}>
          {React.cloneElement(children as any, { id: htmlFor, required })}
          {help && !helpMessage && <Notice intent="none">{help}</Notice>}
          {helpMessage && <Notice>{helpMessage}</Notice>}
        </Stack>
      </IntentProvider>
    </div>
  );
}

const emptyState = { intent: undefined, message: "" };

const FormContext = React.createContext<{
  values: Record<string, any>;
  wviState: Record<string, ValidationState>;
  isLoading: boolean;
  onValueChange(name: string, value: string | null): Record<string, any>;
  onValidate(field: string): Promise<void>;
  onChangeAndValidate(field: string, value: string | null): Promise<void>;
}>({
  values: {},
  wviState: {},
  isLoading: false,
  onValueChange(name: string, value: string) {
    return {};
  },
  onValidate(name: string) {
    return Promise.resolve();
  },
  onChangeAndValidate(name: string, value: string | null) {
    return Promise.resolve();
  },
});

export function useFormValues() {
  return useContext(FormContext).values;
}

export function useFormField(name: string, validationType: validationType) {
  const { values, wviState, isLoading, onValueChange, onValidate, onChangeAndValidate } =
    useContext(FormContext);
  return useMemo(
    () => ({
      value: values[name],
      helpMessage: wviState[name]?.message,
      intent: wviState[name]?.intent,
      isLoading: isLoading,
      onValueChange: validationType === "ON_CHANGE" ? onChangeAndValidate : onValueChange,
      onBlur: validationType === "ON_BLUR" ? onValidate : () => {},
    }),
    [
      values[name],
      wviState[name]?.message,
      wviState[name]?.intent,
      validationType,
      isLoading,
      onChangeAndValidate,
      onValueChange,
      onValidate,
    ]
  );
}

type FormProps = Omit<React.ComponentProps<"form">, "onSubmit"> & {
  initialValues: Record<string, any>;
  onSubmit(values: Record<string, any>): Promise<Record<string, any>>;
  onValidate(
    field: string,
    entity: Record<string, any>
  ): Promise<ValidationResult<Record<string, any>>>;
};

export const Form = React.forwardRef<HTMLFormElement, FormProps>(function Form(
  { initialValues, onValidate: onValidateFromProps, onSubmit: onSubmitFromProps, ...restProps },
  ref
) {
  const [values, setValues] = useState(initialValues);
  const [wviState, setWviState] = useState<Record<string, ValidationState>>(() => {
    const wvi: Record<string, ValidationState> = {};
    const keys = Object.keys(initialValues);
    for (let index = 0; index < keys.length; index++) {
      wvi[keys[index]] = emptyState;
    }
    return wvi;
  });
  const [isLoading, setLoading] = useState<boolean>(false);

  const onValueChange = useCallback(
    (field: string, value: any) => {
      let newState = {};
      setValues((oldState) => {
        newState = { ...oldState, [field]: value };
        return newState;
      });
      return newState;
    },
    [setValues]
  );

  const onValidate = useCallback(
    async (field: string) => {
      setLoading(true);
      const validationResult = await onValidateFromProps(field, values);
      setWviState((oldState) => ({ ...oldState, [field]: validationResult.state }));
      setValues(validationResult.entity);
      setLoading(false);
    },
    [setLoading, setValues, setWviState, onValidateFromProps, values]
  );

  const onChangeAndValidate = useCallback(
    async (field: string, value: string | null) => {
      const newState = onValueChange(field, value);
      setLoading(true);
      const validationResult = await onValidateFromProps(field, newState);
      setWviState((oldState) => ({ ...oldState, [field]: validationResult.state }));
      setValues(validationResult.entity);
      setLoading(false);
    },
    [setLoading, onValueChange, onValidateFromProps, setWviState, setValues]
  );

  const providerValues = useMemo(() => {
    return { values, wviState, isLoading, onValueChange, onValidate, onChangeAndValidate };
  }, [values, wviState, isLoading, onValueChange, onValidate, onChangeAndValidate]);

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const data = await onSubmitFromProps(values);
      setValues(data ?? values);
    },
    [onSubmitFromProps, values]
  );

  return (
    <FormContext.Provider value={providerValues}>
      <form ref={ref} {...restProps} onSubmit={onSubmit} />
    </FormContext.Provider>
  );
});
